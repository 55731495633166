import React, {useEffect, useState} from 'react';
import Loading from "../../../Layouts/Loading/Loading";
import {validateString} from "../../../../commons/public-validation";
import xToast from "../../../../commons/xToast";
import {webRequest} from "../../../../Infrastructure/services/apiService";
import useAppContext from "../../../../Infrastructure/state/useAppContext";
import {validateResponse} from "../../../../Infrastructure/services/validateResponse";

const DenyMessages = ({messages}: any) => {

  let [_messages, setMessages] = useState([])

  let [message, setMessage] = useState("")
  let [loading, setLoading] = useState(false)
  let {state, dispatch} = useAppContext()


  const checkKeyDown = (event: any) => {
    if (event.key.toLowerCase() === "enter") send()
  }

  useEffect(() => {
    scrollDown()
  }, []);

  const scrollDown = () => {

    let nestedElement: any = document.getElementById("messages-content")
    if (nestedElement) {
      setTimeout(function () {
        nestedElement.scrollTo(0, nestedElement.scrollHeight)
      }, 100)
    } else {
      setTimeout(function () {
        scrollDown()
      }, 200)
    }
  }

  const send = () => {
    if (loading) return;

    try {
      validateString(message, "متن پیام", {min: 3, max: 400})
      setLoading(true)
      webRequest({
        url: "/registration/sendMessage",
        auth: state.auth,
        data: {
          message
        }
      }).then(response => {
        try {
          setLoading(false)
          validateResponse(response)
          setMessages(response.payload)
          setMessage("")
          scrollDown()

        } catch (e: any) {

          if (e.message === "login") {
            dispatch({
              type: "on-login",
              payload: {
                auth: null,
                isVendor: false,
                isApproved: false,
                vendor: null,
                registration: {
                  type: null,
                  page: "login"
                }
              }
            })
          }

        }

      })
    } catch (e: any) {
      xToast.error(e.message)
    }
  }

  let records: any[] = []
  if (_messages.length > 0) {
    records = _messages
  } else {
    records = messages
  }


  const back = () => {
    dispatch({
      type: "update-registration",
      payload: {
        ...state.registration,
        page: "documents",
        level: 4
      }
    })
  }

  return (
    <div>
      <div className={"container ma"}>
        <div style={{maxWidth: "1200px", width: "calc(100% - 32px)", marginLeft: "auto", marginRight: "auto"}}>
          <div className={"card mt-3"} style={{padding: "8px"}}>
            <div>

              <div className={"mb-2 ps-1"} style={{display: "flex", justifyContent: "space-between"}}>
                <div>
                  پیام ها
                </div>
                <div onClick={back} style={{cursor: "pointer", borderBottom: "1px dashed #1d74e7", color: "#1d74e7", fontSize: "14px"}}>
                  بازگشت و اصلاح مدارک
                </div>
              </div>

              <div className={"message-box"} id={"messages-content"}>
                {records.map((message: any, index: number) => (
                  <div key={index} style={{marginBottom: "16px"}}>
                    <div key={index} className={`message-${message.sender}-type`}>

                      {message.sender == "admin" && <>
                        <div className={`type-box ${message.sender}`}>
                          <img src={"/assets/img/avatar.png"} alt="" className={"admin-avatar"}/>
                        </div>
                        &nbsp;&nbsp;
                      </>
                      }

                      <div className={`${message.sender}-message`}>
                        <div>
                          {message.message}
                        </div>
                      </div>
                    </div>
                    <div style={{clear: "both"}}></div>
                  </div>
                ))}
              </div>
              <div style={{position: "relative", overflow: "hidden", border: "1px solid #eee", borderRadius: "8px", marginTop: "8px"}}>
                <input type="text" onKeyDown={checkKeyDown} className={"form-control"} style={{border: "0"}} placeholder={"متن پیام"} value={message} onChange={(e: any) => setMessage(e.target.value)}/>
                <div style={{position: "absolute", left: "0", background: "#fff", top: "0", width: "32px", textAlign: "center", cursor: "pointer", height: "100%", alignItems: "center", display: "flex", justifyContent: "center"}}>
                  {loading && <Loading width={20} height={20} strokeWidth={2}/>}
                  {!loading && <span className={"ti ti-arrow-left"} onClick={send}></span>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DenyMessages;