import React, {useRef, useState} from 'react';
import "./page-auth.css"
import Input from "../../../Ui/Input/Input";
import {webRequest} from "../../../../Infrastructure/services/apiService";
import {getOnlyNumbers} from "../../../../commons/numberHelper";
import {validateResponse} from "../../../../Infrastructure/services/validateResponse";
import xToast, {XToast} from "../../../../commons/xToast";
import {numberToTime} from "./numberToTime";
import useAppContext from "../../../../Infrastructure/state/useAppContext";

const LoginPage = () => {
  const [mobile, setMobile] = useState("");
  const [code, setCode] = useState("");
  let [status, setStatus] = useState("index");
  let timerRef: any = useRef()
  let [timerInterval, setTimerInterval] = useState<any>(undefined)

  let {dispatch} = useAppContext()

  const submitForm = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    let toast = xToast.loading("لطفا صبر کنید")

    webRequest({
      url: "/auth/send-code",
      data: {
        username: mobile,
      }
    }).then(response => {
      initSentCodeCallback(response, toast)
    })

  }

  const confirmCode = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    let toast = xToast.loading("لطفا صبر کنید")




    webRequest({
      url: "/auth/confirm-code",
      data: {
        username: mobile,
        code: code
      }
    }).then(response => {
      initConfirmCodeCallback(response, toast)
    })

  }

  const initSentCodeCallback = (response: any, toast: XToast) => {
    setCode("")

    try {
      validateResponse(response)

      toast.updateAndDismiss("success", response.payload.message, 3000)
      setTimeout(() => toast.dismiss(), 3000)


      let status = response.payload.status
      if (status === "sentBefore") status = "sent"
      setStatus(status)

      let time = response.payload.time

      if (timerRef && timerRef.current) {
        timerRef.current!.innerText = numberToTime(time)
        time--
      }

      if (timerInterval) clearInterval(timerInterval)

      let interval = setInterval(() => {

        if (time === 0) {
          clearInterval(timerInterval)
          setStatus("timeout")
          return;
        }

        if (timerRef && timerRef.current) {
          timerRef.current!.innerText = numberToTime(time)
          time--
        }

      }, 1000)
      setTimerInterval(interval)


    } catch (e: any) {
      toast.updateAndDismiss("error", e.message, 3000)
    }
  }

  const initConfirmCodeCallback = (response: any, toast: XToast) => {

    try {
      validateResponse(response)
      toast.updateAndDismiss("success", "ورود با موفقیت انجام شد", 3000)
      dispatch({
        type: "on-login",
        payload: response.payload
      })
    } catch (e: any) {
      toast.updateAndDismiss("error", e.message, 3000)
    }
  }

  let _mobile = getOnlyNumbers(mobile)

  return (
    <div className="authentication-wrapper authentication-cover authentication-bg" style={{background: "#fff"}}>
      <div className="authentication-inner row">
        {/* /Left Text */}
        {/*<div className="d-none d-lg-flex col-lg-7 p-0">*/}
        {/*  <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center" style={{background: "#f8f7fa"}}>*/}
        {/*    <img*/}
        {/*      alt="auth-login-cover"*/}
        {/*      className="img-fluid my-5 auth-illustration"*/}
        {/*      data-app-dark-img="illustrations/auth-login-illustration-dark.png"*/}
        {/*      data-app-light-img="illustrations/auth-login-illustration-light.png"*/}
        {/*      src="/assets/img/illustrations/auth-login-illustration-light.png"*/}
        {/*    />*/}
        {/*    <img*/}
        {/*      alt="auth-login-cover"*/}
        {/*      className="platform-bg"*/}
        {/*      data-app-dark-img="illustrations/bg-shape-image-dark.png"*/}
        {/*      data-app-light-img="illustrations/bg-shape-image-light.png"*/}
        {/*      src="/assets/img/illustrations/bg-shape-image-light.png"*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="d-flex col-12 col-lg-12 align-items-center p-sm-5 p-4">
          <div className="w-px-400 mx-auto">
            <div className="app-brand mb-4">
              <a className="app-brand-link gap-2" href="https://mashinno.com">
            <span className="" style={{textAlign: "center", width: "100%"}}>
              <img src="https://mashinno.com/uploads/settings/settings-13-color.png" alt="" width={160}/>
            </span>
              </a>
            </div>
            {/* /Logo */}
            <h5 className="mb-1" style={{color: "#222"}}>به پنل فروشندگان ماشین نو خوش آمدید! 👋</h5>
            <p className="mb-4" style={{fontSize: "13px"}}>
              لطفا به حساب کاربری خود وارد شوید تا از امکانات سامانه استفاده کنید.
            </p>
            <form
              onSubmit={status === "index" ? submitForm : confirmCode}
              className="mb-3"
              id="formAuthentication"
              method="GET"
            >
              <div className="mb-3">
                {status === "index" ? <Input type={"tel"} label={"شماره موبایل"} value={_mobile} center={true} hint={"مثال : 09120000000"} setValue={setMobile} /> :
                  <Input type={"tel"} label={"کد تایید"} value={code} center={true} setValue={setCode} onEnter={confirmCode}/>
                }
              </div>


              <div style={{textAlign: "center", marginBottom: "16px", display: status === "sent" ? "block" : "none"}}>
                <span ref={timerRef}></span>
              </div>

              <div style={{textAlign: "center", marginBottom: "16px", display: status === "timeout" ? "block" : "none"}}>
                <span style={{cursor: "pointer", borderBottom: "1px dashed #888", fontSize: "14px"}} onClick={submitForm}>ارسال مجدد کد ورود</span>
              </div>

              {status === "index" && <button className="btn btn-primary d-grid w-100" onClick={submitForm}>ارسال کد تایید</button>}
              {status === "sent" && <button className="btn btn-primary d-grid w-100" onClick={confirmCode}>تایید کد</button>}
            </form>

          </div>
        </div>
        {/* /Login */}
      </div>
    </div>


  );
};

export default LoginPage;