import axios from "axios"
import {appConfig} from "../config";
import {UserState} from "../state/Types/UserState";


interface ApiServiceRequest {
  url: string,
  data?: object,
  method?: string,
  auth?: UserState|null,
  version?: number,
}

export const webRequest = async (props: ApiServiceRequest) => {


  let version = props.version
  if (!version) {
    version = 1;
  }

  const endpointUrl = `${appConfig.api}/vendor/v` + version


  const axiosInstance = axios.create({
    baseURL: endpointUrl,
  })

  axiosInstance.interceptors.request.use(
    (config) => {
      return config;
    },
    err => {
      Promise.reject(err)
      return
    }
  )


  const config = {
    "Content-Type": "multipart/form-data",
    method: props.method,
    headers: props.auth ? {Authorization: `Bearer ${props.auth?.authorisation?.token}`} : {},
  };


  axiosInstance.interceptors.response.use(
    res => {
      return res;
    },
    err => {
      const res = err?.response
      if (res) {
        if (res.status === 500) {
          Promise.reject(err)
        }
      }

      Promise.reject(err)
      return;
    }
  )

  let data = props.data
  if (!data) data = {}
  if (appConfig.debugMode) {
    data = {...data, _dev: true}
  }

  let result
  if (props.auth) {
    result = await axiosInstance.post(endpointUrl + props.url, data, config)
  } else {
    result = await axiosInstance.post(endpointUrl + props.url, data, config)
  }

  if (result) {

    return await result.data;
  } else {
    return {
      notFound: true
    };
  }
}
