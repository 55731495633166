import React, {useEffect, useState} from 'react';

interface Props {
  lastPage: number | undefined,
  currentPage: number | undefined,
  total: number | undefined,
  clicked: Function,
}

const Pagination = (props: Props) => {

  let [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(true)
  }, [props.currentPage]);

  if (!loaded) return <></>


  if (typeof props.lastPage !== "number" || props.lastPage === 1) {
    return <></>
  }


  let buildLink = (page: number): number => {
    return page;
  }


  let getPage = (page: number, activePage: number) => {
    return <li className={`page-item cp ${page === activePage ? "active" : ""}`} key={page * activePage}>
      <span className="page-link  rounded-3" onClick={() => props.clicked(buildLink(activePage))}>
        {activePage}
      </span>
    </li>
  }

  let result = []


  if (loaded && props.lastPage > 1) {
    // result.push(getPage(1, props.currentPage!))
  }


  let isAddedFirst = false;
  let isAddedLast = false;
  for (let i = 1; i < props.lastPage!; i++) {

    let step = 2
    if (props.currentPage == 1) {
      step = 4
    }


    if (props.currentPage! + step < i) {
      if (!isAddedLast) {
        result.push(<li className={`page-item cp`} key={i}>
          <span className="page-link rounded-3">
            ...
          </span>
        </li>)
        isAddedLast = true
      }
      continue
    }

    if (props.currentPage! - step > i) {
      if (!isAddedFirst) {
        result.push(<li className={`page-item cp`} key={-i}>
          <span className="page-link rounded-3">
            ...
          </span>
        </li>)
        isAddedFirst = true
      }
      continue
    }


    result.push(
      <li className={`page-item cp  ${props.currentPage === i ? "active" : ""}`} key={i}>
        <span className="page-link rounded-3" onClick={() => props.clicked(buildLink(i))}>
          {i}
        </span>
      </li>
    )
  }
  if (loaded && props.lastPage > 1) {
    result.push(getPage(props.currentPage!, props.lastPage!))
  }


  return (
    <div className="col-12">
      <div className="my-paginate my-4">
        <nav aria-label="Index navigation example">
          <ul className="pagination flex-wrap justify-content-center">

            {props.currentPage! > 1 && <li className={`page-item cp ${props.currentPage == 1 ? "disabled" : ""}`}>
              <span className="page-link rounded-3"  onClick={() => props.clicked(buildLink(props.currentPage! - 1))}>
                قبلی
              </span>
            </li>}


            {result}

            {props.lastPage !== props.currentPage && <li className="page-item" key={-2}>
              <span className="page-link rounded-3"  onClick={() => props.clicked(buildLink(props.currentPage! + 1))}>
                بعدی
              </span>
            </li>}


          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Pagination;
