import React, {useEffect, useState} from 'react';
import Dialog from "../../../Layouts/Dialog/Dialog";
import {ProductLite} from "../../../../Infrastructure/services/Types/Product";
import {webRequest} from "../../../../Infrastructure/services/apiService";
import useAppContext from "../../../../Infrastructure/state/useAppContext";

import "./priceList.css"
import {numberFormat} from "../../../../commons/numberHelper";
import {validateResponse} from "../../../../Infrastructure/services/validateResponse";
import xToast from "../../../../commons/xToast";
import Skeleton from "react-loading-skeleton";
import {dismissConfirmDialog, showConfirmDialog} from "../../../../hooks/confirmDialog";
import EditPriceDialog from "./EditPriceDialog";

interface Props {
  onCancel: Function,
  onAddPrice: Function,
  onEditClicked: Function,
  product: ProductLite | null
}

export interface IPriceListRow {
  id: number,
  brand: string,
  description: string | null,
  country: string,
  finalPrice: number,
  approved: boolean,
  image: string | null,
  images: string[],
  isMine: boolean,
  mainPrice: number,
}


const PriceSkeleton = () => {
  return (
    <tr>
      <td>
        <div><Skeleton width={80} height={20}/></div>
        <div><Skeleton width={80} height={20}/></div>
      </td>

      <td>
        <div><Skeleton width={80} height={20}/></div>
        <div><Skeleton width={80} height={20}/></div>
      </td>
      <td><Skeleton width={80} height={20}/></td>
      <td>
        <div>
          <Skeleton width={80} height={20}/>
        </div>
      </td>
      <td>
        <div style={{display: "flex", justifyContent: "center"}}>
          <Skeleton width={20} height={20}/>
          &nbsp;
          <Skeleton width={20} height={20}/>
        </div>
      </td>

    </tr>
  );
};


const PriceListDialog = (props: Props) => {
  let {state} = useAppContext();

  let [loaded, setLoaded] = useState(false)
  let [prices, setPrices] = useState<IPriceListRow[]>([]);

  let cancel = () => {
    props.onCancel()
  }


  useEffect(() => {
    if (props.product) {
      getData()
    } else {
      setPrices([])
      setLoaded(false)
    }

  }, [props.product]);

  let getData = () => {
    setLoaded(false)
    webRequest({
      url: "/products/price-list",
      auth: state.auth,
      data: {
        productId: props.product!.id
      }
    }).then(response => {
      try {
        validateResponse(response)
        setPrices(response.payload)
        setLoaded(true)
      } catch (e: any) {
        xToast.error(e.message)
      }

    })
  }


  const showDescription = (row: any) => {
    let toast = xToast.loading("در حال بررسی")
    toast.update("info", row.description)
  }


  const edit = (row: any) => {
    props.onEditClicked(row)

  }

  const deleteRow = (row: any) => {
    showConfirmDialog({
      width: 300,
      height: 160,
      description: "آیا از حذف این قیمت اطمینان دارید؟",
      confirmButtonText: "بله حذف شود",
      negativeButtonText: "خیر",
      title: "حذف قیمت",
      zIndex: 4001,
      onConfirmButtonClicked: () => {
        let toast = xToast.loading("در حال حذف قیمت")

        webRequest({
          url: "/delete-price",
          auth: state.auth,
          data: {
            id: row.id
          }
        }).then(response => {
          try {
            validateResponse(response)
            getData()
            toast.updateAndDismiss("success", "قیمت با موفقیت حذف گردید", 3000)
            dismissConfirmDialog()
          } catch (e: any) {
            toast.updateAndDismiss("error", e.message, 3000)
          }


        })
      }
    })
  }

  let [editPrice, setEditPrice] = useState(-1)
  let confirm = () => {

  }


  return (
    <>
      {props.product && <Dialog show={props.product != null} setShow={cancel} height={600} width={800} title={"لیست قیمت های محصول"}>
        {props.product.isInMyProduct && <div style={{paddingBottom: "8px", float: "left"}}>
          <span className={"btn btn-primary"} onClick={() => props.onAddPrice(props.product)}>افزودن قیمت</span>
        </div>}

        <table className={"table table-bordered"} style={{textAlign: "center", borderRadius: "8px"}}>
          <thead>
          <tr>
            <th>برند و کشور</th>
            <th>قیمت</th>
            <th>تصویر</th>
            <th>توضیحات</th>
            {props.product.isInMyProduct && <th>عملیات</th>}
          </tr>
          </thead>
          <tbody>

          {loaded && <>
            {prices.map((row, index) => {
              let imagePath = "";
              let hasImage = false;
              let imageCount = row.images.length;

              row.images.map((img: any) => {
                hasImage = true
                imagePath = "https://static.mashinno.com/vendor/products/" + img[500]
              })


              return <tr key={index} style={{background: !row.approved ? "#f4ec91" : "transparent"}}>
                <td>
                  <div>{row.brand}</div>
                  <div>{row.country}</div>
                </td>

                <td>
                  {row.mainPrice !== row.finalPrice && <del>{numberFormat(row.mainPrice)}</del>}
                  <div>{numberFormat(row.finalPrice)}</div>
                </td>
                <td>
                  {hasImage && <>
                    {imageCount == 1 && <img src={imagePath} alt="" width="80" style={{borderRadius: "12px"}}/>}
                    {imageCount > 1 && <div style={{display: "flex", justifyContent: "space-evenly"}}>
                      {row.images.map((img: any) => {
                        return <img src={"https://static.mashinno.com/vendor/products/" + img[500]} style={{width: "48px", borderRadius: "4px"}}/>
                      })}
                    </div>}
                  </>}
                  {!hasImage && <>-</>}
                </td>
                <td>
                  {row.description && <span className={"btn btn-success btn-sm"} onClick={() => showDescription(row)}>مشاهده</span>}
                  {!row.description && <>-</>}
                  {!row.approved && <div>
                    در انتظار تایید
                  </div>}
                </td>
                {props.product!.isInMyProduct && <td>
                  {row.isMine ? <>
                      <>
                        <i className={"ti ti-trash cursor-pointer"} onClick={() => deleteRow(row)}></i>
                        &nbsp;
                        <i className={"ti ti-edit cursor-pointer"} onClick={() => edit(row.id)}></i>
                      </>
                    </> :
                    <>-</>}
                </td>}

              </tr>
            })}
          </>}


          {!loaded && <>
            <PriceSkeleton/>
            <PriceSkeleton/>
            <PriceSkeleton/>
            <PriceSkeleton/>
          </>}


          {prices.length === 0 && loaded && <tr>
            <td colSpan={5} style={{textAlign: "center", lineHeight: "48px"}}>
              قیمتی برای این محصول ثبت نشده است
            </td>
          </tr>}
          </tbody>
        </table>
      </Dialog>}
    </>
  )
}

export default PriceListDialog;