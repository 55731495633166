
import "./VerticalTimeline.css";
import React from 'react'

function VerticalTimeLine() {
    return (

        <>


            <div className="container visiblee">
                <div className="row">
                    <div className="col-md-12">
                        <div style={{ display: 'inline-block', width: '100%', overflowY: 'auto' }}>
                            <ul className="timeline timeline-horizontal" style={{textAlign:"center"}}>
                                <li className="timeline-item">
                                    <div className="timeline-badge success">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="black" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0" />
                                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                                        </svg>
                                    </div>
                                    <div className="timeline-panel">
                                        <div className="timeline-heading">
                                            <span className="timeline-title" style={{fontWeight : "bold"}}>ثبت نام در پنل</span>
                                        </div>
                                        <div className="timeline-body">
                                        {/*<p style={{ fontSize: 12 }}>ثبت اطلاعات حقیقی یا حقوقی و انتظار برای تایید هویت</p>*/}
                                        </div>
                                    </div>
                                </li>
                                <li className="timeline-item">
                                    <div className="timeline-badge danger">

                                        <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="black" className="bi bi-camera-video" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z" />
                                        </svg>

                                    </div>
                                    <div className="timeline-panel">
                                        <div className="timeline-heading">
                                            <span className="timeline-title" style={{fontWeight : "bold"}}>آموزش نحوه کار با پنل</span>
                                        </div>
                                        <div className="timeline-body">
                                        {/*<p style={{ fontSize: 12 }} >تماشای آموزش های مربوط به هر بخش پنل برای استفاده راحت تر</p>*/}
                                        </div>
                                    </div>
                                </li>
                                <li className="timeline-item">
                                    <div className="timeline-badge primary">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="black" className="bi bi-bag-plus" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5" />
                                            <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                                        </svg>

                                    </div>
                                    <div className="timeline-panel">
                                        <div className="timeline-heading">
                                            <span className="timeline-title" style={{fontWeight : "bold"}}>ثبت محصول و ایجاد فروشگاه</span>
                                        </div>
                                        <div className="timeline-body">
                                        {/*<p style={{ fontSize: 12 }}>ثبت محصولاتی که به مشتریان خود ارائه می دهید، به همراه قیمت</p>*/}
                                        </div>
                                    </div>
                                </li>
                                <li className="timeline-item">
                                    <div className="timeline-badge warning">

                                        <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="black" className="bi bi-save2" viewBox="0 0 16 16">
                                            <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v4.5h2a.5.5 0 0 1 .354.854l-2.5 2.5a.5.5 0 0 1-.708 0l-2.5-2.5A.5.5 0 0 1 5.5 6.5h2V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1z" />
                                        </svg>

                                    </div>
                                    <div className="timeline-panel">
                                        <div className="timeline-heading">
                                            <span className="timeline-title" style={{fontWeight : "bold"}}>آغاز فعالیت در ماشین نو</span>
                                        </div>
                                        <div className="timeline-body">
                                        {/*<p style={{ fontSize: 12 }}>شروع فروش محصولات خود در فروشگاه اینترنتی ماشین نو</p>*/}
                                        </div>
                                    </div>
                                </li>


                            </ul>
                        </div>
                    </div>
                </div>
            </div>











        </>


    )
}

export default VerticalTimeLine



