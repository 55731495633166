import React, {useState} from 'react';
import Dialog from "../../Layouts/Dialog/Dialog";

import "./app-chat.css"
import {validateString} from "../../../commons/public-validation";
import xToast from "../../../commons/xToast";
import {webRequest} from "../../../Infrastructure/services/apiService";
import useAppContext from "../../../Infrastructure/state/useAppContext";
import {validateResponse} from "../../../Infrastructure/services/validateResponse";

const AddTicketDialog = ({show, setShow, confirmAndStart}: any) => {
  let [active, setActive] = useState<string | undefined>(undefined);
  let [keyword, setKeyword] = useState("");

  let {state} = useAppContext()

  let clicked = (department: any) => {
    setActive(department)
  }


  let startChat = (department: string | undefined, subject: string) => {
    let toast = xToast.loading("در حال بررسی درخواست")

    webRequest({
      url: "/tickets/create",
      data: {department, subject},
      auth: state.auth
    }).then(response => {
      try {
        validateResponse(response)
        setActive(undefined)
        setKeyword("")
        setShow(false)
        confirmAndStart(response.payload.records, response.payload.current)
        toast.dismiss()
      } catch (e: any) {
        toast.updateAndDismiss("error", response.message)
      }
    })
  }


  const confirm = () => {
    try {
      validateString(keyword, "عنوان گفت و گو", {min: 6, max: 60})
      startChat(active, keyword)


    } catch (e: any) {

      xToast.error(e.message)
      return;
    }

  }

  const close = () => {
    setShow(false);
    setActive(undefined)
    setKeyword("")
  }

  return (
    <>
      <Dialog show={show} setShow={close} height={active ? 490 : 350} width={500} title={"لطفا موضوع تیکت خود را انتخاب نمایید"}>
        <div style={{marginTop: "16px"}}>
          <div className={"subject"} onClick={() => clicked("product")}>
            {active == "product" && <i className={"ti ti-check"}></i>}
            <span>محصولات</span>
          </div>
          <div className={"subject"} onClick={() => clicked("order")}>
            {active == "order" && <i className={"ti ti-check"}></i>}
            <span>سفارش</span>
          </div>

          <div className={"subject"} onClick={() => clicked("accounting")}>
            {active == "accounting" && <i className={"ti ti-check"}></i>}
            مسائل مالی
          </div>
          <div className={"subject"} onClick={() => clicked("score")}>
            {active == "score" && <i className={"ti ti-check"}></i>}
            امتیاز فروشگاه ( به زودی )
          </div>

          <div className={"subject"} onClick={() => clicked("other")}>
            {active == "other" && <i className={"ti ti-check"}></i>}
            سایر موارد
          </div>
          {active && <div className={"mt-3"}>
            <label htmlFor="">عنوان گفت و گو</label>
            <input type={"text"} className={"form-control"} onChange={e => setKeyword(e.target.value)} value={keyword} style={{height: "48px"}} placeholder={"عنوان گفت وگو را اینجا وارد کنید"}/>
            <span className={"btn btn-primary mt-2"} style={{width: "100%", height: "48px"}} onClick={confirm}>تایید و شروع گفت و گو</span>
          </div>}
        </div>

      </Dialog>
    </>
  );
};

export default AddTicketDialog;