


export function numberFormat(number:any) {
  if (number === "") return "";
  let a = new Intl.NumberFormat();
  return a.format(number)
}

export const isNumeric = (data: any) => {
  try {
    let result = parseInt(data)
    return Number.isInteger(result);
  } catch (e: any) {
    return false;
  }
}



export function getOnlyNumbers(data: any): string {
  try {
    if (data.trim().length === 0) return "";

    const numberPattern = /\d+/g;
    let numberResult = data.match(numberPattern)
    if (numberResult == null) {
      return "0";
    }
    let result = "";
    numberResult.map((row: any) => {
      result += row
    })
    return result;
  } catch (e) {
    return "0";
  }

}

