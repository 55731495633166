import {isNumeric} from "./numberHelper";

export function validateString(text: any, name: any, {min = 2, max = 10000, numeric = false, equals = 0} = {}) {

  if (equals > 0) {
    let type = "کاراکتر"
    if (numeric) type = " رقم "
    if (text.length !== equals) throw new Error(name + " باید  " + equals + type + "  باشد ")
  }


  if (text.length < min) throw new Error(name + " نباید کمتر از " + min + " کاراکتر باشد ");
  if (text.length > max) throw new Error(name + " نباید بیشتر از " + max + " کاراکتر باشد ");
  if (numeric && !isNumeric(text)) throw new Error(name + " باید عددی باشد ")
}


export function validateNumber(text: any, name: any, {min = 0, max = Number.MAX_VALUE} = {}) {

  const number = parseInt(text)
  if (Number.isNaN(number)) throw new Error("فیلد " + name + " باید عددی باشد ")

  if (number < min) throw new Error("فیلد " + name + " نباید کمتر از " + min + " باشد ")

  if (number > max) throw new Error("فیلد " + name + " نباید بیشتر از " + max + " باشد ")

}


export function validateEmail(text: any, name: any, {required = true} = {}) {

  if (!required && text.trim().length === 0) return;

  // if (text.length < min) throw new Error(name + " نباید کمتر از " + min + " کاراکتر باشد ");
  //
  // if (text.length > max) throw new Error(name + " نباید بیشتر از " + max + " کاراکتر باشد ");

  if (!String(text).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) throw new Error(name + " وارد شده اشتباه است ");

}

export function validateArray(array: any, name: any) {
  if (!Array.isArray(array)) throw new Error("فیلد " + name + " الزامی است ");
}


export function validateSelect(data: any, name: any) {
  try {
    if (!data.value) {
      throw new Error("فیلد " + name + " الزامی است ");
    }
  } catch (e) {
    throw new Error("فیلد " + name + " الزامی است ");
  }

}


export function validateNotIsNull(data: any, name: any) {
  if (data === null || data === undefined || data === "") throw new Error(" فیلد " + name + " نباید خالی باشد ");
}


