import React, {useEffect, useState} from 'react';
import useAppContext from "../../../../Infrastructure/state/useAppContext";
import {webRequest} from "../../../../Infrastructure/services/apiService";
import {useNavigate} from "react-router-dom";
import Loading from "../../../Layouts/Loading/Loading";
import "./done-page.css"
import DenyMessages from "./DenyMessages";

const DonePage = () => {

  let {state, dispatch} = useAppContext()
  let navigate = useNavigate()
  let [mode, setMode] = useState("loading")
  let [messages, setMessages] = useState<any[]>([])

  const scrollDown=()=>{
    let nestedElement:any=document.getElementById("messages-content")
    if (nestedElement)nestedElement.scrollTo(0, nestedElement.scrollHeight);
  }

  useEffect(() => {
    if (!state.auth) {
      return;
    }
    webRequest({
      url: "/registration/check",
      auth: state.auth
    }).then(response => {
      try {
        setMode(response.payload.mode)
        if (response.payload.mode == "deny") {
          setMessages(response.payload.messages)
          setTimeout(function (){
            scrollDown()
          },1000)
        }
        let result = response.payload;

        if (result.state == "login") {
          dispatch({
            type: "on-login",
            payload: {
              auth: null,
              isVendor: false,
              isApproved: false,
              vendor: null,
              registration: {
                type: null,
                page: "login"
              }
            }
          })
          navigate("/")
        } else if (result.state == "page") {
          // setTutorials(result.tutorials)
        } else if (result.state == "vendor" && result.confirmed) {

          dispatch({
            type: "on-login",
            payload: response.payload.auth
          })
          navigate("/panel")
        }

      } catch (e: any) {

      }
    })

  }, [state.auth]);

  return (
    <div className={"container ma"}>
      <div style={{maxWidth: "1200px", marginTop: "48px", width: "calc(100% - 32px)", marginLeft: "auto", marginRight: "auto"}}>
        <div className={"card mt-3"} style={{padding: "8px"}}>

          {mode == "notApproved" && <div style={{display: "flex", justifyContent: "space-between"}}>
            <div>
              <div style={{color: "#25a85a", fontWeight: "bold"}}>
                ثبت نام شما با موفقیت انجام شد
              </div>
              <div style={{color: "#666", marginTop: "1px"}}>
                نتیجه بررسی مدارک به شما اطلاع داده خواهد شد
              </div>
            </div>

            <div style={{background: "#fcfaf1", color: "#f7bf2a", width: "120px", textAlign: "center", lineHeight: "42px"}} className={"text-warning"}>
              <i className={"ti ti-clock"} style={{fontSize: '16px', paddingLeft: "4px"}}></i>
              در انتظار تایید
            </div>
          </div>}

          {mode == "deny" && <div style={{display: "flex", justifyContent: "space-between"}}>
            <div>
              <div style={{color: "#25a85a", fontWeight: "bold"}}>
                ثبت نام شما با موفقیت انجام شد
              </div>
              <div style={{color: "#666", marginTop: "1px"}}>
                درخواست شما بررسی شد
              </div>
            </div>

            <div style={{background: "#fcfaf1", color: "#f7bf2a", width: "120px", textAlign: "center", lineHeight: "42px"}} className={"text-warning"}>
              <i className={"ti ti-clock"} style={{fontSize: '16px', paddingLeft: "4px"}}></i>
              رد شده
            </div>
          </div>}




          {/*{(!loaded) && <div style={{display: "flex", justifyContent: "space-between"}}>*/}
          {/*  <div>*/}
          {/*    <div>*/}
          {/*      <Skeleton width={200} height={10}/>*/}
          {/*    </div>*/}
          {/*    <div>*/}
          {/*      <Skeleton width={280} height={10}/>*/}
          {/*    </div>*/}
          {/*  </div>*/}

          {/*  <div>*/}
          {/*    <Skeleton width={100} height={40}/>*/}
          {/*  </div>*/}
          {/*</div>}*/}

          {mode == "loading" && <div className={"tac"}>
            <Loading text={"در حال بررسی اطلاعات"}/>
          </div>}


        </div>

        {/*<div style={{color: "#222", fontSize: "13px"}} className={" mt-3 mb-1"}>*/}
        {/*  شما می توانید تا زمان تایید مدارک توسط ماشین نو آموزش استفاده از پنل را مشاهده نمایید*/}
        {/*</div>*/}

        {/*<div className={"card"} style={{padding: "8px"}}>*/}
        {/*  <div className={"row row-small-padding"}>*/}
        {/*    {tutorials.map((row: any, index) => {*/}
        {/*      return <div key={index} className={"col-md-4 mb-4"}>*/}
        {/*        <video src={"https://dash.mashinno.com/" + row.videoPath} controls={true} style={{width: "100%", borderRadius: "8px"}}></video>*/}
        {/*        {row.title}*/}
        {/*      </div>*/}
        {/*    })}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      {mode == "deny" && <DenyMessages messages={messages}/>}

    </div>
  );
};

export default DonePage;


