import React from 'react';
import HeaderProfile from "./HeaderProfile/HeaderProfile";

const Header = () => {
  return (
    <nav
      className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
      id="layout-navbar"
    >
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
        <a
          className="nav-item nav-link px-0 me-xl-4"
          href="#"
        >
          <i className="ti ti-menu-2 ti-sm"/>
        </a>
      </div>
      <div
        className="navbar-nav-right d-flex align-items-center"
        id="navbar-collapse"
      >
        <div className="navbar-nav align-items-center">
          <div className="nav-item dropdown-style-switcher dropdown me-2 me-xl-0">
            <a
              className="nav-link dropdown-toggle hide-arrow"
              data-bs-toggle="dropdown"
              // href="javascript:void(0);"
            >
              <i className="ti ti-md"/>
            </a>
            <ul className="dropdown-menu dropdown-menu-start dropdown-styles">
              <li>
                <a
                  className="dropdown-item"
                  data-theme="light"
                  // href="javascript:void(0);"
                >
                    <span className="align-middle">
                      <i className="ti ti-sun me-2"/>
                      روز
                    </span>
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  data-theme="dark"
                  // href="javascript:void(0);"
                >
                    <span className="align-middle">
                      <i className="ti ti-moon me-2"/>
                      شب
                    </span>
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  data-theme="system"
                  // href="javascript:void(0);"
                >
                    <span className="align-middle">
                      <i className="ti ti-device-desktop me-2"/>
                      سیستم
                    </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <HeaderProfile/>
      </div>
    </nav>
  );
};

export default Header;