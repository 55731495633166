import React, {useEffect, useState} from 'react';
import useAppContext from "../../../Infrastructure/state/useAppContext";
import {webRequest} from "../../../Infrastructure/services/apiService";
import xToast from "../../../commons/xToast";
import {dismissConfirmDialog, showConfirmDialog} from "../../../hooks/confirmDialog";
import RegisterContent from "../Auth/Content/RegisterContent";
import HtmlText from "../../Ui/HtmlText";
import Checkbox from "../../Ui/Checkbox";
import Skeleton from "react-loading-skeleton";

const ContractPage = () => {
  let {state, dispatch} = useAppContext();
  let [loaded, setLoaded] = useState(false);
  let [text, setText] = useState<string>("");
  let [vendorData, setVendorData] = useState<any>(undefined);
  let [categories, setCategories] = useState<[]>([]);

  let [isChecked, setIsChecked] = useState<boolean>(false);


  useEffect(() => {

    webRequest({
      url: "/registration/getContract",
      auth: state.auth
    }).then(response => {
      setText(response.payload.text)
      setVendorData(response.payload.data)
      setCategories(response.payload.categories)
      setLoaded(true)
    })
  }, [])


  const confirm = () => {
    try {

      if (!isChecked) {
        xToast.error("پذیرش قرارداد الزامی است")
        return;
      }

      let toast = xToast.loading("در حال بررسی اطلاعات")

      webRequest({
        url: "/registration/confirmContract",
        auth: state.auth
      }).then(response => {
        toast.dismiss()
        dispatch({
          type: "on-login",
          payload: {
            ...state,
            ...response.payload
          }
        })
      })

    } catch (e: any) {

    }
  }


  const back = () => {
    showConfirmDialog({
      title: "بازگشت؟",
      height: 160,
      description: "آیا می خواهید به مرحله قبلی برگردید؟",
      onConfirmButtonClicked: () => {
        dispatch({
          type: "update-registration",
          payload: {
            ...state.registration,
            page: "documents",
            level: 4
          }
        })

        dismissConfirmDialog()

      }
    })
  }


  return (
    <>
      <div className={"card"}>
        <div className={"container"}>




          <div className={"col-md-12 ma"}>
            {loaded && <>
              <HtmlText text={text}/>

              <div className={"tac mt-1 mb-4"} style={{color: "#222", fontWeight: "bold"}}>
                کمسیون بر اساس دسته بندی
              </div>

              <table className={"table table-bordered tac"}>
                <thead>
                <tr>
                  <th>ردیف</th>
                  <th>دسته بندی</th>
                  <th>درصد</th>
                  <th>توضیحات</th>
                </tr>
                </thead>

                <tbody>
                {categories.map((row: any, index: number) => {
                  return <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{row.label}</td>
                    <td>{row.commission}</td>
                    <td>{row.description && row.description.length > 0 ? row.description : row.label}</td>
                  </tr>
                })}
                </tbody>

              </table>

              <br/>


            </>}

            {!loaded && <>
              <Skeleton width={"100%"} height={"12px"}/>
              <Skeleton width={"100%"} height={"12px"}/>
              <Skeleton width={"100%"} height={"12px"}/>
              <Skeleton width={"80%"} height={"12px"}/>
              <br/>
              <Skeleton width={"100%"} height={"12px"}/>
              <Skeleton width={"20%"} height={"12px"}/>
              <br/>
              <Skeleton width={"100%"} height={"12px"}/>
              <Skeleton width={"100%"} height={"12px"}/>
              <Skeleton width={"60%"} height={"12px"}/>
              <br/>
              <Skeleton width={"100%"} height={"12px"}/>
              <Skeleton width={"100%"} height={"12px"}/>
              <Skeleton width={"100%"} height={"12px"}/>
              <Skeleton width={"100%"} height={"12px"}/>
              <Skeleton width={"100%"} height={"12px"}/>
              <Skeleton width={"100%"} height={"12px"}/>
              <Skeleton width={"100%"} height={"12px"}/>
              <Skeleton width={"100%"} height={"12px"}/>
              <Skeleton width={"100%"} height={"12px"}/>
              <Skeleton width={"20%"} height={"12px"}/>
              <br/>
              <Skeleton width={"100%"} height={"12px"}/>
              <Skeleton width={"100%"} height={"12px"}/>
              <Skeleton width={"60%"} height={"12px"}/>
              <br/>
              <Skeleton width={"100%"} height={"12px"}/>
              <Skeleton width={"100%"} height={"12px"}/>
              <Skeleton width={"60%"} height={"12px"}/>
              <br/>


            </>}
          </div>



        </div>
      </div>
    </>
  );
};

export default ContractPage;