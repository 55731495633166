import React, {useEffect, useState} from 'react';
import useAppContext from "../../../Infrastructure/state/useAppContext";
import {webRequest} from "../../../Infrastructure/services/apiService";
import {numberFormat} from "../../../commons/numberHelper";
import {useParams} from "react-router-dom";


const getLogo=()=>{
  return "https://mashinno.com/uploads/settings/settings-13-color.png";
}


const Invoice = () => {


  let {state, dispatch} = useAppContext()
  let [invoice, setInvoice] = useState<any | null>(null)
  let params=useParams()
  let [hasError, setHasError] = useState(false)


  useEffect(() => {

    if (!state.isLoaded) {
      return
    }


    webRequest({
      url: "/invoice",
      data: {
        q:params.id,
        vendor:true
      },
      auth: state.auth
    }).then(response => {
      try {
        console.log(response)
        setInvoice(response.payload)
      } catch (e) {
        setHasError(true)
      }

    })

  }, []);

  console.log(invoice)

  return (
    <>
      <div style={{width: "100%", maxWidth: "1460px", margin: "40px auto", padding: "10px", overflow: "auto",color:"#222"}}>
        {hasError && <h1 style={{fontSize: "20px"}} className={"tac"}>
          فاکتور یافت نشد
        </h1>}

        {invoice && <div className={"table-responsive"} dir={"rtl"} style={{width: "1400px", margin: "auto"}}>
          <table width="100%" cellPadding={5} align="center" style={{paddingBottom: 8}}>
            <tbody>
            <tr>
              <td>
                <table
                  width="100%"
                  align="center"
                  cellSpacing={0}
                  cellPadding={3}
                  border={0}
                  dir="rtl"
                >
                  <tbody>
                  <tr>
                    <td align="right" rowSpan={2} style={{width: "30%"}}>
                      <picture>
                        <source srcSet={getLogo()}/>
                        <img
                          width="125px"
                          src={getLogo()}
                          alt=""
                          style={{border: 0, width: 125}}
                        />
                      </picture>
                    </td>
                    <td
                      align="center"
                      style={{
                        fontSize: 20,
                        verticalAlign: "middle",
                        fontWeight: "bold",
                        width: "40%",
                        textAlign: "left",
                        paddingTop: "20px"
                      }}
                    >
                      فاکتور سفارش کالا و خدمات
                    </td>

                  </tr>
                  </tbody>
                </table>
                <div id="main-invoice" style={{marginTop: 32}}>
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        '\n\n                    @media print {\n            @page {\n              size: landscape\n            }\n          }\n\n          body {\n            font-size: 13px;\n          }\n\n          * {\n             ;\n            font-size: 12px !important;\n            line-height: 25px;\n          }\n\n        '
                    }}
                  />
                  <table
                    width="100%"
                    align="center"
                    cellSpacing={0}
                    cellPadding={0}
                    border={0}
                    dir="rtl"
                    style={{fontSize: 11}}
                  >
                    <tbody>
                    <tr>
                      <td
                        style={{
                          /*width: 790, */ verticalAlign: "top",
                          textAlign: "right"
                        }}
                      >
                        <div style={{display: "flex"}}>
                          <div
                            style={{
                              width: 66,
                              height: 48,
                              display: "inline-block",
                              border: "1px solid #888",
                              transform: "rotate(270deg)",
                              textAlign: "center",
                              lineHeight: 20,
                              borderRadius: 8,
                              background: "#eee",
                              verticalAlign: "middle",
                              position: "relative",
                              paddingTop: 16
                            }}
                          >
                            حق العمل کار
                          </div>
                          <div
                            style={{
                              border: "1px solid #3c3c3c",
                              borderRadius: 5,
                              marginLeft: 8,
                              width: "calc(100%)"
                            }}
                          >
                            <table
                              width="100%"
                              align="center"
                              cellSpacing={0}
                              cellPadding={0}
                              border={0}
                              dir="rtl"
                            >
                              <tbody>
                              <tr>
                                <td
                                  colSpan={8}
                                  style={{width: 200, paddingRight: 10}}
                                >
                                  <div style={{display: "flex"}}>
                                    <div style={{marginLeft: 32}}>
                                  <span style={{fontWeight: "bold"}}>
                                    فروشنده :
                                  </span>
                                      <span> قطعه یاب هوشمند خودرو</span>
                                    </div>
                                    <div style={{marginLeft: 32}}>
                                  <span style={{fontWeight: "bold"}}>
                                    شناسه ملی :
                                  </span>
                                      <span>14007624820</span>
                                    </div>
                                    <div style={{marginLeft: 32}}>
                                  <span style={{fontWeight: "bold"}}>
                                    شماره ثبت :
                                  </span>
                                      <span>526908</span>
                                    </div>
                                    <div>
                                  <span style={{fontWeight: "bold"}}>
                                    کد اقتصادی :{" "}
                                  </span>
                                      <span>14007624820</span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={4} style={{paddingRight: 10}}>
                              <span style={{fontWeight: "bold"}}>
                                نشانی شرکت:{" "}
                              </span>
                                  تهران ، خیابان جمهوری ، کوچه مراغه ای ، بن بست یاس
                                  شرقی پلاک ۱۱
                                </td>
                                <td style={{minWidth: 200, paddingRight: 10}}>
                              <span style={{fontWeight: "bold"}}>
                                شماره تماس :{" "}
                              </span>
                                  02191301374
                                </td>
                                <td colSpan={3}>
                              <span style={{fontWeight: "bold"}}>
                                کدپستی :{" "}
                              </span>
                                  1146834648
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                          <style
                            dangerouslySetInnerHTML={{
                              __html:
                                "\n                  .ltable td {\n                    height: 33px !important;\n                    font-size: 11px !important;\n                  }\n\n                  .ltable {\n                    height: 66px;\n                  }\n\n                "
                            }}
                          />
                          <div
                            style={{
                              border: "1px solid #3c3c3c",
                              borderRadius: 5,
                              height: 66
                            }}
                          >
                            <table
                              width="100%"
                              align="center"
                              cellSpacing={0}
                              cellPadding={0}
                              dir="rtl"
                              className="ltable"
                              style={{height: 66, width: 180}}
                            >
                              <tbody>
                              <tr>
                                <td
                                  style={{
                                    fontSize: 10,
                                    height: 18,
                                    paddingTop: "4px",
                                    paddingRight: "4px",
                                    fontWeight: "bold",
                                    textAlign: "right"
                                  }}
                                >
                                  تاریخ :
                                  <span style={{direction: "ltr", textAlign: "left", display: "inline-block"}}>
                                {invoice.date}
                                </span>
                                </td>

                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontSize: 10,
                                    height: 18,
                                    padding: "0",
                                    paddingRight: "4px",
                                    textAlign: "right",
                                    fontWeight: "bold",
                                    width: "107px"
                                  }}
                                >
                                  شماره پیگیری :
                                  <span style={{direction: "ltr"}}>
                                  {invoice.orderId}
                                </span>
                                </td>

                              </tr>
                              <tr></tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table
                    width="100%"
                    align="center"
                    cellSpacing={0}
                    cellPadding={0}
                    border={0}
                    dir="rtl"
                    style={{fontSize: 11, marginTop: 8, marginBottom: 8}}
                  >
                    <tbody>
                    <tr>
                      <td
                        style={{
                          /*width: 790, */ verticalAlign: "top",
                          textAlign: "right"
                        }}
                      >
                        <div style={{display: "flex"}}>
                          <div
                            style={{
                              width: 66,
                              height: 48,
                              display: "inline-block",
                              border: "1px solid #888",
                              transform: "rotate(270deg)",
                              textAlign: "center",
                              lineHeight: 20,
                              borderRadius: 8,
                              background: "#eee",
                              verticalAlign: "middle",
                              position: "relative",
                              paddingTop: 16
                            }}
                          >
                            خریدار
                          </div>
                          <div
                            style={{
                              border: "1px solid #3c3c3c",
                              borderRadius: 5,
                              marginLeft: 8,
                              width: "calc(100%)",
                              height: 66
                            }}
                          >
                            <table
                              width="100%"
                              align="center"
                              cellSpacing={0}
                              cellPadding={0}
                              border={0}
                              dir="rtl"
                              style={{height: 66}}
                            >
                              <tbody>

                              <tr>
                                <td style={{width: 200, paddingRight: 10}}>
                              <span style={{fontWeight: "bold"}}>
                                خریدار :
                              </span>
                                  <span>{invoice.name}</span>
                                </td>
                                <td style={{width: 200, paddingRight: 10}}>
                              <span style={{fontWeight: "bold"}}>
                                کد پستی :
                              </span>
                                  <span/>
                                </td>
                                <td style={{width: 200, paddingRight: 10}}>
                              <span style={{fontWeight: "bold"}}>
                                شماره تماس : &nbsp;
                              </span>
                                  <span>{invoice.phone}</span>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={4} style={{paddingRight: 10}}>
                              <span style={{fontWeight: "bold"}}>
                                آدرس خریدار :{" "}
                              </span>
                                  <span>
                                  {invoice.address}
                              </span>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                          <style
                            dangerouslySetInnerHTML={{
                              __html:
                                "\n                  .ltable td {\n                    height: 22px !important;\n                    font-size: 11px !important;\n                  }\n\n                  .ltable {\n                    height: 66px;\n                  }\n                "
                            }}
                          />
                          <div
                            style={{
                              border: "1px solid #3c3c3c",
                              borderRadius: 5,
                              height: 66
                            }}
                          >
                            <table
                              width="100%"
                              align="center"
                              cellSpacing={0}
                              cellPadding={0}
                              dir="rtl"
                              className="ltable"
                              style={{height: 66, width: 180}}
                            >
                              <tbody>
                              <tr>
                                <td
                                  style={{
                                    fontSize: 10,
                                    height: 18,
                                    padding: "0 15px",
                                    fontWeight: "bold"
                                  }}
                                >
                                  کد مشتری :
                                </td>
                                <td>{invoice.userCode}</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table
                    width="100%"
                    align="center"
                    cellSpacing={0}
                    cellPadding={0}
                    dir="rtl"
                    style={{
                      fontSize: 11,
                      textAlign: "center",
                      border: "1px solid #3c3c3c"
                    }}
                  >
                    <tbody>
                    <tr style={{backgroundColor: "#f0f0f0"}}>
                      <td
                        style={{
                          borderBottom: "1px solid #3c3c3c",
                          padding: "5px 0",
                          width: 30
                        }}
                      >
                        ردیف
                      </td>
                      <td
                        style={{
                          borderRight: "1px solid #3c3c3c",
                          padding: "5px 0",
                          borderBottom: "1px solid #3c3c3c",
                          width: 60
                        }}
                      >
                        کد کالا
                      </td>
                      <td
                        style={{
                          borderRight: "1px solid #3c3c3c",
                          padding: "5px 0",
                          borderBottom: "1px solid #3c3c3c",
                          width: 280
                        }}
                      >
                        شرح کالا
                      </td>
                      <td
                        style={{
                          borderRight: "1px solid #3c3c3c",
                          padding: "5px 0",
                          borderBottom: "1px solid #3c3c3c",
                          width: 280
                        }}
                      >
                        آمر
                      </td>
                      <td
                        style={{
                          borderRight: "1px solid #3c3c3c",
                          padding: "5px 0",
                          borderBottom: "1px solid #3c3c3c",
                          width: 100
                        }}
                      >
                        برند
                      </td>
                      <td
                        style={{
                          borderRight: "1px solid #3c3c3c",
                          padding: "5px 0",
                          borderBottom: "1px solid #3c3c3c",
                          width: 35
                        }}
                      >
                        تعداد
                      </td>
                      <td
                        style={{
                          borderRight: "1px solid #3c3c3c",
                          padding: "5px 0",
                          borderBottom: "1px solid #3c3c3c",
                          width: 60
                        }}
                      >
                        مبلغ واحد
                        <br/>( تومان )
                      </td>
                      <td
                        style={{
                          borderRight: "1px solid #3c3c3c",
                          padding: "5px 0",
                          borderBottom: "1px solid #3c3c3c",
                          width: 60
                        }}
                      >
                        تخفیف
                      </td>
                      <td
                        style={{
                          borderRight: "1px solid #3c3c3c",
                          padding: "5px 0",
                          borderBottom: "1px solid #3c3c3c",
                          width: 60
                        }}
                      >
                        مبلغ کل
                        <br/>( تومان )
                      </td>
                      <td
                        style={{
                          borderRight: "1px solid #3c3c3c",
                          padding: "5px 0",
                          borderBottom: "1px solid #3c3c3c",
                          width: 80
                        }}
                      >
                        جمع مالیات و
                        <br/>
                        عوارض (تومان)
                      </td>
                      <td
                        style={{
                          borderRight: "1px solid #3c3c3c",
                          padding: "5px 0",
                          borderBottom: "1px solid #3c3c3c",
                          width: 150
                        }}
                      >
                        جمع مبلغ کل بعلاوه مالیات
                        <br/>و عوارض (تومان)
                      </td>
                    </tr>
                    {invoice.records.map((row:any, index:number) => {
                      return <tr key={index}>
                        <td
                          style={{
                            borderRight: "1px solid #3c3c3c",
                            padding: "5px 0",
                            borderBottom: "1px solid #3c3c3c"
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid #3c3c3c",
                            padding: "5px 0",
                            borderBottom: "1px solid #3c3c3c"
                          }}
                        >
                          {row.code}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid #3c3c3c",
                            padding: "5px 3px",
                            borderBottom: "1px solid #3c3c3c"
                          }}
                        >
                          {row.name}
                          <div></div>
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid #3c3c3c",
                            padding: "5px 3px",
                            borderBottom: "1px solid #3c3c3c"
                          }}
                        >
                          {row.seller}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid #3c3c3c",
                            padding: "5px 0",
                            borderBottom: "1px solid #3c3c3c",
                            textAlign: "center"
                          }}
                        >
                          {row.brand}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid #3c3c3c",
                            padding: "5px 0",
                            borderBottom: "1px solid #3c3c3c"
                          }}
                        >
                          {row.quantity}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid #3c3c3c",
                            padding: "5px 0",
                            borderBottom: "1px solid #3c3c3c"
                          }}
                        >
                          {numberFormat(row.amount)}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid #3c3c3c",
                            padding: "5px 0",
                            borderBottom: "1px solid #3c3c3c"
                          }}
                        >
                          {row.discount}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid #3c3c3c",
                            padding: "5px 0",
                            borderBottom: "1px solid #3c3c3c"
                          }}
                        >
                          {numberFormat(row.qtyAmount)}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid #3c3c3c",
                            padding: "5px 0",
                            borderBottom: "1px solid #3c3c3c"
                          }}
                        >
                          {row.tax}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid #3c3c3c",
                            padding: "5px 0",
                            borderBottom: "1px solid #3c3c3c"
                          }}
                        >
                          {numberFormat(row.fullAmount)}
                        </td>
                      </tr>
                    })}

                    <tr>
                      <td
                        colSpan={5}
                        style={{paddingRight: 5, height: 30, textAlign: "center"}}
                      >
                        جمع کل
                      </td>
                      <td style={{borderRight: "1px solid #3c3c3c"}}>{invoice.finalQuantity}</td>
                      <td style={{borderRight: "1px solid #3c3c3c"}}>{numberFormat(invoice.finalAmount)}</td>
                      <td style={{borderRight: "1px solid #3c3c3c"}}>{invoice.finalDiscount}</td>
                      <td style={{borderRight: "1px solid #3c3c3c"}}>{numberFormat(invoice.finalQtyAmount)}</td>
                      <td style={{borderRight: "1px solid #3c3c3c"}}>{invoice.tax}</td>
                      <td style={{borderRight: "1px solid #3c3c3c"}}>{numberFormat(invoice.finalFullAmount)}</td>
                    </tr>
                    </tbody>
                  </table>
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #222",

                      margin: "8px auto",
                      padding: 8
                    }}
                  >
                    <div>
                      مهر و امضای فروشنده:
                      <br/>
                      عکس
                    </div>
                    <div style={{marginRight: 100}}>
                      تاریخ تحویل
                      <br/>
                      {invoice.time}
                    </div>
                    <div style={{marginRight: 100}}>
                      روش پرداخت :
                      <br/>
                      {invoice.gatewayTitle}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>}

      </div>
    </>
  );
};

export default Invoice;


