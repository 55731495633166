import React, {useEffect, useRef, useState} from 'react';
import Input from "../../../../Ui/Input/Input";
import {validateString} from "../../../../../commons/public-validation";
import xToast from "../../../../../commons/xToast";
import {webRequest} from "../../../../../Infrastructure/services/apiService";
import {getOnlyNumbers} from "../../../../../commons/numberHelper";
import useAppContext from "../../../../../Infrastructure/state/useAppContext";
import {validateResponse} from "../../../../../Infrastructure/services/validateResponse";
import RegisterContent from "../../Content/RegisterContent";
import {toast} from "react-toastify";

const CompleteTrueInformation = () => {

  let {state, dispatch} = useAppContext();

  let nationFileRef: any = useRef();
  let newspaperFileRef: any = useRef();
  let managementFileRef: any = useRef();

  let [name, setName] = React.useState<string>("");
  let [family, setFamily] = React.useState<string>("");
  let [nationCode, setNationCode] = React.useState<string>("");
  let [mobile, setMobile] = React.useState<string>("");

  let [image1, setImage1] = React.useState<string>("");
  let [image2, setImage2] = React.useState<string>("");
  let [image3, setImage3] = React.useState<string>("");

  let [organizationName, setOrganizationName] = useState<string>("");
  let [taxTrackingCode, setTaxTrackingCode] = useState<string>("");
  let [economicCode, setEconomicCode] = useState<string>("");

  useEffect(()=>{
    setTimeout(() => toast.dismiss(), 3000)
  },[])


  const confirm = () => {
    try {
      validateString(organizationName, "نام شرکت", {min: 3, max: 30})
      validateString(economicCode, "کد اقتصادی", {min: 3, max: 30})
      validateString(taxTrackingCode, "کد رهگیری مالیاتی", {min: 3, max: 30})
      validateString(mobile, "شماره تماس", {min: 11, max: 11})

      validateString(name, "نام نماینده", {min: 3, max: 30})
      validateString(family, "نام خانوادگی نماینده", {min: 3, max: 30})
      validateString(nationCode, "کد ملی", {min: 10, max: 10})

      if (image1.length < 10) throw new Error("تصویر کارت ملی الزامی است")
      if (image2.length < 10) throw new Error("تصویر روزنامه کاری الزامی است")
      if (image3.length < 10) throw new Error("تصویر تغییرات مدیریتی الزامی است")

      let toast = xToast.loading("در حال ذخیره اطلاعات")

      webRequest({
        url: "/registration/completeInformation",
        data: {
          type: "legal",
          name, family, nationCode, mobile,
          organizationName, taxTrackingCode, economicCode,
          nationImage: image1,
          newspaperImage: image2,
          managementImage: image3
        },
        auth: state.auth
      }).then(response => {
        try {
          validateResponse(response)
          toast.updateAndDismiss("success", response.payload.message, 3000)

          dispatch({
            type: "update-registration",
            payload: {
              ...state.registration,
              information: response.payload.data,
              page: "address",
              level: 3
            }
          })


        } catch (e: any) {
          toast.updateAndDismiss("error", e.message, 3000)
        }
      })


    } catch (e: any) {
      xToast.error(e.message);
    }
  }


  let _nationCode = getOnlyNumbers(nationCode)
  let _mobile = getOnlyNumbers(mobile)


  const back = () => {

    dispatch({
      type: "update-registration",
      payload: {
        ...state.registration,
        page: "information",
        level: 2
      }
    })


  }


  let isValid = () => {
    try {
      validateString(organizationName, "نام شرکت", {min: 3, max: 30})
      validateString(economicCode, "کد اقتصادی", {equals:11})
      validateString(taxTrackingCode, "کد رهگیری مالیاتی", {equals:11})
      validateString(mobile, "شماره تماس", {min: 11, max: 11})

      validateString(name, "نام نماینده", {min: 3, max: 30})
      validateString(family, "نام خانوادگی نماینده", {min: 3, max: 30})
      validateString(nationCode, "کد ملی", {equals: 10})

      if (image1.length < 10) throw new Error("تصویر کارت ملی الزامی است")
      if (image2.length < 10) throw new Error("تصویر روزنامه کاری الزامی است")
      if (image3.length < 10) throw new Error("تصویر تغییرات مدیریتی الزامی است")

      return true
    } catch (e: any) {
      return false
    }
  }

  let canContinue = isValid()


  let footer = <>
    <div style={{cursor: "pointer", borderBottom: "1px dashed "}} className={"text-primary"} onClick={back}>
      بازگشت
    </div>
    <div style={{width: "32px"}}></div>
    <div onClick={confirm}>
      <span className={`btn btn-confirm-footer ${canContinue ? "btn-primary" : "btn-dark"}`}>ثبت و ادامه</span>
    </div>
  </>

  const nationFileChanged = (event: any) => {
    let fileReader = new FileReader()
    fileReader.onloadend = () => {
      let image: any = fileReader.result
      setImage1(image)
    }
    fileReader.readAsDataURL(event.target.files[0]);
  }

  const newspaperFileChanged = (event: any) => {
    let fileReader = new FileReader()
    fileReader.onloadend = () => {
      let image: any = fileReader.result
      setImage2(image)
    }
    fileReader.readAsDataURL(event.target.files[0]);
  }


  const managementFileChanged = (event: any) => {
    let fileReader = new FileReader()
    fileReader.onloadend = () => {
      let image: any = fileReader.result
      setImage3(image)
    }
    fileReader.readAsDataURL(event.target.files[0]);
  }


  return (
    <RegisterContent title={"اطلاعات شخص حقوقی"} footer={footer}>

      <div className={"row row-small-padding mt-3"}>
        <Input parentClass={"col-6"} label={"نام شرکت"} center={true} value={organizationName} setValue={setOrganizationName}/>
        <Input parentClass={"col-6"} label={"کد اقتصادی"} center={true} value={economicCode} setValue={setEconomicCode}/>
      </div>

      <div className={"row row-small-padding mt-3"}>
        <Input parentClass={"col-6"} label={"کد رهگیری مالیاتی"} center={true} value={taxTrackingCode} setValue={setTaxTrackingCode}/>
        <Input parentClass={"col-6"} label={"شماره تماس"} value={_mobile} center={true} setValue={setMobile}/>
      </div>


      <div className={"row row-small-padding mt-3"}>
        <Input parentClass={"col-6"} label={"نام نماینده"} center={true} value={name} setValue={setName}/>
        <Input parentClass={"col-6"} label={"نام خانوادگی نماینده"} center={true} value={family} setValue={setFamily}/>
      </div>


      <div className={"row row-small-padding mt-3"}>
        <Input parentClass={"col-6"} label={"کد ملی نماینده"} value={_nationCode} center={true} setValue={setNationCode}/>
      </div>

      <input type="file" ref={nationFileRef} style={{display: "none"}} accept="image/*" onChange={nationFileChanged}/>
      <input type="file" ref={newspaperFileRef} style={{display: "none"}} accept="image/*" onChange={newspaperFileChanged}/>
      <input type="file" ref={managementFileRef} style={{display: "none"}} accept="image/*" onChange={managementFileChanged}/>


      <div style={{width: '300px', height: "200px", border: "1px dashed #aaa", borderRadius: "12px", margin: "24px auto", textAlign: "center", lineHeight: "200px", cursor: "pointer", position: "relative"}} onClick={() => nationFileRef.current.click()}>
        <span style={{borderBottom: "1px dashed #aaa"}}>تصویر کارت ملی</span>
        <img src={image1} alt="" width="280px" height="180px" style={{position: "absolute", left: "0", right: "0", top: "0", bottom: "0", margin: "auto auto", borderRadius: "12px", border: "1px dashed #fff"}}/>
      </div>


      <div style={{width: '300px', height: "200px", border: "1px dashed #aaa", borderRadius: "12px", margin: "24px auto", textAlign: "center", lineHeight: "200px", cursor: "pointer", position: "relative"}} onClick={() => newspaperFileRef.current.click()}>
        <span style={{borderBottom: "1px dashed #aaa"}}>تصویر روزنامه کاری</span>
        <img src={image2} alt="" width="280px" height="180px" style={{position: "absolute", left: "0", right: "0", top: "0", bottom: "0", margin: "auto auto", borderRadius: "12px", border: "1px dashed #fff"}}/>
      </div>


      <div style={{width: '300px', height: "200px", border: "1px dashed #aaa", borderRadius: "12px", margin: "24px auto", textAlign: "center", lineHeight: "200px", cursor: "pointer", position: "relative"}} onClick={() => managementFileRef.current.click()}>
        <span style={{borderBottom: "1px dashed #aaa"}}> تصویر تغییرات مدیریتی</span>
        <img src={image3} alt="" width="280px" height="180px" style={{position: "absolute", left: "0", right: "0", top: "0", bottom: "0", margin: "auto auto", borderRadius: "12px", border: "1px dashed #fff"}}/>
      </div>


      {/*<div style={{borderTop: "1px solid #eee", paddingTop: "8px"}}>*/}
      {/*  <span className={"btn btn-primary"} onClick={confirm}>*/}
      {/*    تایید و ذخیره اطلاعات*/}
      {/*  </span>*/}
      {/*</div>*/}

    </RegisterContent>
  );
};

export default CompleteTrueInformation;