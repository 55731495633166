import React, {useEffect} from 'react';
import RegisterContent from "../Content/RegisterContent";
import Input from "../../../Ui/Input/Input";
import {getOnlyNumbers} from "../../../../commons/numberHelper";
import TextArea from "../../../Ui/TextArea/TextArea";
import xToast from "../../../../commons/xToast";
import {validateEmail, validateString} from "../../../../commons/public-validation";
import {webRequest} from "../../../../Infrastructure/services/apiService";
import useAppContext from "../../../../Infrastructure/state/useAppContext";
import {validateResponse} from "../../../../Infrastructure/services/validateResponse";
import {dismissConfirmDialog, showConfirmDialog} from "../../../../hooks/confirmDialog";

const AddressPage = () => {

  let {state, dispatch} = useAppContext();
  let [postalCode, setPostalCode] = React.useState<string>("");
  let [address, setAddress] = React.useState<string>("");
  let [email, setEmail] = React.useState<string>("");
  let [phone, setPhone] = React.useState<string>("");


  useEffect(() => {

    try {
      setPostalCode(state.registration.address.postalCode)
      setAddress(state.registration.address.address)
      setEmail(state.registration.address.email)
      setPhone(state.registration.address.phone)
    } catch (e: any) {

    }

  }, [])

  let validate = () => {
    validateString(postalCode, "کد پستی", {numeric: true, equals: 10})
    validateEmail(email, "ایمیل", {required: false})
    validateString(phone, "شماره ثابت", {numeric: true, equals: 11})
    validateString(address, "آدرس", {min: 12, max: 300})
  }


  const confirm = () => {
    try {
      validate()

      let toast = xToast.loading("در حال ذخیره اطلاعات")
      webRequest({
        url: "/registration/address",
        data: {
          postalCode, email, phone, address
        },
        auth: state.auth!
      }).then(response => {
        try {
          validateResponse(response)
          let result = response.payload;


          toast.updateAndDismiss("success", result.message, 3000)

          dispatch({
            type: "update-registration",
            payload: {
              ...state.registration,
              address: response.payload.data,
              page: "documents",
              level: 4
            }
          })


        } catch (e: any) {
          toast.updateAndDismiss("error", e.message, 3000)
        }
      })


    } catch (e: any) {
      xToast.error(e.message)
    }
  }


  const back = () => {
    showConfirmDialog({
      title: "بازگشت؟",
      height: 160,
      description: "آیا می خواهید به مرحله قبلی برگردید؟",
      onConfirmButtonClicked: () => {
        dispatch({
          type: "update-registration",
          payload: {
            ...state.registration,
            page: state.registration.information.type === "true" ? "true-information" : "legal-information",
            level: 2
          }
        })
        dismissConfirmDialog()
      }
    })
  }

  const isValid = () => {
    try {
      validate()
      return true
    } catch (e) {
      return false
    }
  }

  let _postalCode = getOnlyNumbers(postalCode)


  let footer = <>
    <div style={{cursor: "pointer", borderBottom: "1px dashed "}} className={"text-primary"} onClick={back}>
      بازگشت
    </div>
    <div style={{width: "32px"}}></div>
    <div onClick={confirm}>
      <span className={`btn btn-confirm-footer ${isValid() ? "btn-primary" : "btn-dark"}`}>ثبت و ادامه</span>
    </div>
  </>

  return (
    <RegisterContent title={"آدرس و کد پستی"} footer={footer}>
      <Input center={true} label={"کد پستی"} parentClass={"mt-2"} value={_postalCode} setValue={setPostalCode}/>
      <Input center={true} parentClass={"mt-3"} label={"ایمیل ( اختیاری )"} value={email} setValue={setEmail}/>
      <Input center={true} parentClass={"mt-3"} label={"شماره ثابت"} value={phone} setValue={setPhone}/>
      <TextArea parentClass={"mt-3"} label={"آدرس"} value={address} setValue={setAddress}/>
      <div className={"mt-5"}></div>
    </RegisterContent>
  );
};

export default AddressPage;