import React, {useRef} from 'react';


interface Props {
  label: string,
  checked: boolean,
  setChecked: Function,
  parentClass?: string,
}

const Checkbox = (props:Props) => {
  let id=Math.random()+"_"
  let ref:any=useRef()

  const clicked=()=>{
    props.setChecked(ref.current.checked)
  }

  return (
    <div className={`${props.parentClass}`} style={{cursor: "pointer"}} onClick={clicked}>
      <input ref={ref} type="checkbox" id={id} style={{cursor: "pointer"}} checked={props.checked}/>
      &nbsp;
      &nbsp;
      <label htmlFor={id} style={{cursor: "pointer", color: "#333"}}>{props.label}</label>
    </div>

  );
};

export default Checkbox;