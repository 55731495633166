import React from 'react';

interface Props {
  title: string,
  description?: string,
  active: boolean
}

const CheckboxBox = (props: Props) => {
  return (
    <div className={"option-row"}>
      <div>
        <div className={"title"}>{props.title}</div>
        <div className={"description"}>{props.description}</div>
      </div>
      <div style={{display: "flex", alignItems: "center"}}>
        <span className={`radio-button ${props.active?"active":""}`}>
          {props.active && <span className={"checked"}></span>}
        </span>
      </div>
    </div>
  );
};

export default CheckboxBox;