import React from 'react';
import Skeleton from "react-loading-skeleton";

const OrdersSkeleton = () => {
  return (
    <div className={"table-responsive"}>

      <div style={{display: "flex", justifyContent: "center", marginBottom: "8px"}}>
        <Skeleton width={36} height={36}/>&nbsp;
        <Skeleton width={36} height={36}/>&nbsp;
        <Skeleton width={36} height={36}/>&nbsp;
        <Skeleton width={36} height={36}/>&nbsp;
        <Skeleton width={36} height={36}/>&nbsp;
        <Skeleton width={36} height={36}/>
      </div>

      <table className={"table table-bordered"}>
        <thead>

        <tr style={{textAlign: "center", lineHeight: "42px"}}>
          <td>شماره سفارش</td>
          <td>مبلغ فاکتور</td>
          <td>وضعیت</td>
          <td>آدرس</td>
          <td>آخرین پیام</td>
          <td>تاریخ ثبت</td>
          <td>عملیات</td>
        </tr>

        </thead>
        <tbody>
        {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((row, index) => {
          return <tr key={index}>
            <td style={{textAlign: "center", paddingBottom: "8px"}}><Skeleton width="80%" height="20px" style={{margin: "0 auto"}}/></td>
            <td style={{textAlign: "center", paddingBottom: "8px"}}><Skeleton width="80%" height="20px" style={{margin: "0 auto"}}/></td>
            <td style={{textAlign: "center", paddingBottom: "8px"}}><Skeleton width="80%" height="20px" style={{margin: "0 auto"}}/></td>
            <td style={{textAlign: "center", paddingBottom: "8px"}}><Skeleton width="80%" height="20px" style={{margin: "0 auto"}}/></td>
            <td style={{textAlign: "center", paddingBottom: "8px"}}><Skeleton width="80%" height="20px" style={{margin: "0 auto"}}/></td>
            <td style={{textAlign: "center", paddingBottom: "8px"}}><Skeleton width="80%" height="20px" style={{margin: "0 auto"}}/></td>
            <td style={{textAlign: "center", paddingBottom: "8px"}}><Skeleton width="80%" height="20px" style={{margin: "0 auto"}}/></td>
          </tr>
        })}
        </tbody>
      </table>
      <div style={{display: "flex", justifyContent: "center", marginBottom: "8px",marginTop:"8px"}}>
        <Skeleton width={36} height={36}/>&nbsp;
        <Skeleton width={36} height={36}/>&nbsp;
        <Skeleton width={36} height={36}/>&nbsp;
        <Skeleton width={36} height={36}/>&nbsp;
        <Skeleton width={36} height={36}/>&nbsp;
        <Skeleton width={36} height={36}/>
      </div>

    </div>
  );
};

export default OrdersSkeleton;