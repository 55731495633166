import React from 'react';
import "./loading.css"

interface Props {
  width?: number,
  height?: number,
  text?: string,
  strokeWidth?: number
}

const Loading = (props: Props) => {

  let width = 60;
  let height = 60;
  let strokeWidth = 6;
  if (props.width) width = props.width;
  if (props.height) height = props.height;
  if (props.strokeWidth) strokeWidth = props.strokeWidth;

  return (
    <div>
      <svg className="spinner" style={{color: "#4444ff", height: height + "px", width: width + "px", margin: "auto", display: "flex", justifyContent: "center"}}>
        <circle style={{strokeWidth: strokeWidth + "px"}}>
          <animateTransform attributeName="transform" type="rotate" values="-90;810" keyTimes="0;1" dur="2s" repeatCount="indefinite"></animateTransform>
          <animate attributeName="stroke-dashoffset" values="0%;0%;-157.080%" calcMode="spline" keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0" keyTimes="0;0.5;1" dur="2s" repeatCount="indefinite"></animate>
          <animate attributeName="stroke-dasharray" values="0% 314.159%;157.080% 157.080%;0% 314.159%" calcMode="spline" keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0" keyTimes="0;0.5;1" dur="2s" repeatCount="indefinite"></animate>
        </circle>
      </svg>
      {props.text && <div className={"mt-4 tac"}>
        {props.text}
      </div>}

    </div>
  );
};

export default Loading;