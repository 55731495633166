import React from 'react';
import MessageSkeleton from "./MessageSkeleton";

const Chats = ({records,loading,ticket}: {ticket:any,loading:boolean ,records: any }) => {

  if (!ticket) {
    return <div className="chat-history-body bg-body tac">
      گفت و گویی انتخاب نشده است
    </div>
  }

  return (
    <div className="chat-history-body bg-body">
      <ul className="list-unstyled chat-history">

        {loading && <>
          <MessageSkeleton/>
          <MessageSkeleton/>
          <MessageSkeleton/>
          <MessageSkeleton/>
          <MessageSkeleton/>
        </>}

        {records && ticket &&  <>
          {records.map((record: any, index: number) => {
            return record.type == "user" ? <li className="chat-message" key={index}>
              <div className="d-flex overflow-hidden">
                <div className="user-avatar flex-shrink-0 me-3">
                  <div className="avatar avatar-sm">
                    {/*<img alt="آواتار" className="rounded-circle" src="../../assets/img/avatars/2.png"/>*/}
                  </div>
                </div>
                <div className="chat-message-wrapper flex-grow-1 ">
                  <div className="chat-message-text bg-white">
                    <p className="mb-0">{record.message}</p>
                  </div>
                  <div className="text-muted mt-1">
                    <small>{record.created_at}</small>
                  </div>
                </div>
              </div>
            </li> : <li className="chat-message chat-message-right" key={index} style={{color: "#fff"}}>
              <div className="d-flex overflow-hidden">
                <div className="chat-message-wrapper flex-grow-1 w-50">
                  <div className="chat-message-text">
                    <p className="mb-0">{record.message}</p>
                  </div>
                  <div className="text-end text-muted mt-1">
                    <i className="ti ti-checks ti-xs me-1"/>
                    <small>{record.created_at}</small>
                  </div>
                </div>
                <div className="user-avatar flex-shrink-0 ms-3">
                  <div className="avatar avatar-sm">
                    <img alt="آواتار" className="rounded-circle" src="/assets/img/avatar.png"/>
                  </div>
                </div>
              </div>
            </li>

          })}
        </>}


      </ul>
    </div>
  );
};

export default Chats;