import React, {ReactNode, useState} from 'react';
import "./dialog.css"

interface Props {
  title: string,
  children: ReactNode,
  show: boolean,
  setShow: Function,
  height?: number,
  width?: number,
  header?: boolean,
  cancelable?: boolean,
  zIndex?:number

}

const Dialog = (props: Props) => {

  let [active, setActive] = useState(false);
  let [show, setShow] = useState(false);


  let zIndex=4000
  if (props.zIndex) {
    zIndex=props.zIndex
  }



  let header = true
  if (typeof props.header === "boolean") header = props.header;


  let cancelable = true
  if (typeof props.cancelable === "boolean") cancelable = props.cancelable;


  if (typeof window !== 'undefined' && !active && props.show) {
    setTimeout(() => {
      setActive(true)
      setTimeout(() => {
        setShow(true)
        // document.body.style.overflow = 'hidden';
      }, 10)

    }, 40);
  }

  if (!props.show && active) {
    let delay = 800
    if (window.innerWidth < 767) {
      delay = 400
    }
    setTimeout(() => {
      setActive(false)
      // document.body.style.overflow = 'auto';
    }, delay)
    props.setShow(false);
  }

  const close = () => {
    setShow(false)

    let delay = 800
    if (window.innerWidth < 767) {
      delay = 400
    }
    setTimeout(() => {
      setActive(false)
      // document.body.style.overflow = 'auto';
    }, delay)
    props.setShow(false);
  }

  let closeFromCover = (event: any) => {
    if (cancelable){
      if (event.target.classList.contains("dialog-cover")) close()
    }
  }


  return (
    <section className={`dialog-cover ${show ? `show` : ''} ${active ? 'active' : ''}`} style={{zIndex}} onClick={closeFromCover}>
      <div className={`dialog ${show ? `active` : ''}`} style={{height: props.height, width: props.width}}>
        {props.show && <>
          {header && <div className={"dialog-header"}>
            <div style={{color:"#222"}}>{props.title}</div>
            {/*<div className={"close"} onClick={close}>*/}
            {/*  بستن x*/}
            {/*</div>*/}
          </div>}

          <div className={"dialog-body"} style={{height: header?"calc(100% - 48px)":"100%"}}>
            {props.show && <>
              {props.children}
            </>}
          </div>
        </>}
      </div>
    </section>
  );
};

export default Dialog;