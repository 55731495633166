import React, {useEffect, useState} from 'react';
import useAppContext from "../../../../Infrastructure/state/useAppContext";
import {webRequest} from "../../../../Infrastructure/services/apiService";
import RegisterContent from "../Content/RegisterContent";
import {ISelectType} from "../../../../Infrastructure/types/ISelectType";
import XSelect from "../../../Ui/Select/XSelect";
import Skeleton from "react-loading-skeleton";
import xToast from "../../../../commons/xToast";
import {validateResponse} from "../../../../Infrastructure/services/validateResponse";
import {dismissConfirmDialog, showConfirmDialog} from "../../../../hooks/confirmDialog";

const JobTitleAndCategory = () => {
  let {state, dispatch} = useAppContext();

  let [types, setTypes] = useState<ISelectType[]>([]);
  let [categories, setCategories] = useState<ISelectType[]>([]);

  let [selectedType, setSelectedType] = useState<ISelectType[]>([]);
  let [selectedCategory, setSelectedCategory] = useState<ISelectType[]>([]);
  let [loaded, setLoaded] = useState(false);


  const initDefault = (categories: any, types: any) => {
    try {
      let categoryRecords:ISelectType[]=[];

      state.registration.jobTitle.categories.map((id:number) =>{
        let result=categories.filter((row:ISelectType)=>row.value===id)[0]
        if (result){
          categoryRecords.push(result)
        }
      })

      setSelectedCategory(categoryRecords);


      let typeRecords:ISelectType[]=[];

      state.registration.jobTitle.types.map((id:number) =>{
        let result=types.filter((row:ISelectType)=>row.value===id)[0]
        if (result){
          typeRecords.push(result)
        }
      })

      setSelectedCategory(categoryRecords);
      setSelectedType(typeRecords);




    } catch (e: any) {

    }
  }

  useEffect(() => {


    webRequest({
      url: "/registration/category",
      auth: state.auth
    }).then(response => {
      setTypes(response.payload.types)
      setCategories(response.payload.categories)
      setLoaded(true)

      initDefault(response.payload.categories, response.payload.types)

    })
  }, [])


  const confirm = () => {
    try {

      if (selectedCategory.length === 0) {
        xToast.error("دسته بندی انتخاب نشده است")
        return
      }

      if (selectedType.length === 0) {
        xToast.error("عنوان شغلی انتخاب نشده است")
        return;
      }


      let toast = xToast.loading("در حال ذخیره اطلاعات")
      webRequest({
        url: "/registration/jobTitle",
        data: {
          categories: selectedCategory,
          types: selectedType
        },
        auth: state.auth
      }).then(response => {

        try {
          validateResponse(response)
          toast.updateAndDismiss("success", "عملیات با موفقیت انجام شد", 3000)


          dispatch({
            type: "update-registration",
            payload: {
              ...state.registration,
              jobTitle: response.payload.data,
              page: "contract",
              level: 5
            }
          })

        } catch (e: any) {
          toast.updateAndDismiss("success", e.message, 3000)
        }

      })
    } catch (e: any) {

    }
  }


  const back = () => {
    showConfirmDialog({
      title: "بازگشت؟",
      height: 160,
      description: "آیا می خواهید به مرحله قبلی برگردید؟",
      onConfirmButtonClicked: () => {
        dispatch({
          type: "update-registration",
          payload: {
            ...state.registration,
            page: "address",
            level: 3
          }
        })
        dismissConfirmDialog()
      }
    })
  }


  let isValid = false
  if (selectedCategory.length > 0 && selectedType.length > 0) {
    isValid = true
  }


  let footer = <>
    <div style={{cursor: "pointer", borderBottom: "1px dashed "}} className={"text-primary"} onClick={back}>
      بازگشت
    </div>
    <div style={{width: "32px"}}></div>
    <div onClick={confirm}>
      <span className={`btn btn-confirm-footer ${isValid ? "btn-primary" : "btn-dark"}`}>ثبت و ادامه</span>
    </div>
  </>

  return (
    <RegisterContent title={"نوع خدمات"} footer={footer}>

      {loaded && <>
        <XSelect isMultiple={true} records={categories} optionSelected={setSelectedCategory} defaultOption={selectedCategory} label={"دسته بندی"}/>
        <XSelect isMultiple={true} parentClass={"mt-3"} records={types} optionSelected={setSelectedType} defaultOption={selectedType} label={"عنوان شغلی"}/>
      </>}

      {!loaded && <>
        <Skeleton width={"100%"} height={"48px"}/>
        <Skeleton width={"100%"} height={"48px"} className={"mt-5"}/>
      </>}

    </RegisterContent>
  );
};

export default JobTitleAndCategory;