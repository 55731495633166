import React, {useEffect} from "react";
import './Welcomme.css';
import {Link, useNavigate} from "react-router-dom";
import VerticalTimeLine from "./VerticalTimeLine";
import {useState} from "react";


function Welcome(props: any) {

  const [y, setY] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {

    document.title = 'در ماشین نو بفروشید';


    window.addEventListener("scroll", (e) => {
      const scrollingAnime: any = e.currentTarget;
      setY(scrollingAnime.scrollY)
      // console.log(scrollingAnime.scrollY)
      // console.log(y)
    });
    // let today = new Date().toLocaleDateString('fa-IR');
    // console.log(today);
    // let options: any = {year: 'numeric', month: 'long', day: 'numeric'};
    // let today1 = new Date().toLocaleDateString('fa-IR', options);
    // console.log(today1);
    // let today2 = new Date().toLocaleDateString('fa-IR-u-nu-latn');
    // console.log(today2);

  }, []);


  const reJuForward = () => {
    navigate('/auth')
  }


  return (

    <div className="welcome-main">
      {/* header */}
      <div className="header-welcome">
        <div className="welcom-icon">
          <img src={"https://mashinno.com/uploads/settings/settings-13-color.png?c=0"} className="icon-headwel"/>
        </div>
        <div className="welcome-button" style={{width: "300px"}}>
          <text>ورود به پنل فروشندگان</text>
          <Link to='/auth' className="biter-link">
            <button className="biter-head" style={{width: "100px"}}>
              ورود
            </button>
          </Link>
        </div>

      </div>
      {/* header */}


      <div className="welcome-title">
        <div className="fixing-image" style={{backgroundImage: "url('/assets/img/banner.jpg')"}}>
          <div className={"banner-title"}>
            <span className="titlewelcom-span">
              در ماشین نو فروشنده شوید
            </span>
            <div className="titlewelcom-main mt-1">
              <button className="titlewelcom-button" onClick={reJuForward}>ورود یا ثبت نام</button>
            </div>
          </div>
        </div>

      </div>

      <div className={`why-us ${y >= 50 ? "tranlate-XClass" : null}`} id="whyUs">
        <div className={`head-why ${y >= 1 ? "tranlate-XClass1050" : null} ${y >= 0.5 ? "tranlate-XClass850" : null} ${y >= 0 ? "tranlate-XClass650" : null}`}>
          <div className="matn-khati">
            <text>
              چرا با ماشین نو ؟
            </text>
          </div>
          <div className="khati"></div>
        </div>
        <div className="why-row">
          <div className={`why-culomn ${y >= 10 ? "tranlate-XClass1050" : null} ${y >= 35 ? "tranlate-XClass850" : null}  ${y >= 0 ? "tranlate-XClass650" : null}`}>
            <div className="inner-whyicon">
              {/*<FaMoneyCheckAlt size={30} />*/}
              <i className={"fa fa-money-check-alt"}></i>
            </div>
            <div className="inner-whytitle">
              <h5 style={{marginBottom: 0, fontSize: 16}}>
                بیش از چند میلیون بازدید سالانه
              </h5>
            </div>
            <div className="inner-whykhati"></div>
            <div className="inner-whytext">
              <text>کالای شما به بیش از چند میلیون نفر نشان داده می‌شود
              </text>
            </div>
          </div>
          <div className={`why-culomn ${y >= 10 ? "tranlate-XClass1050" : null} ${y >= 35 ? "tranlate-XClass850" : null} ${y >= 0 ? "tranlate-XClass650" : null}`}>
            <div className="inner-whyicon">
              <i className={"fa fa-truck-moving"}></i>
            </div>
            <div className="inner-whytitle">
              <h5 style={{marginBottom: 0, fontSize: 16}}>گزارش فروش و درآمد شفاف
              </h5>
            </div>
            <div className="inner-whykhati"></div>
            <div className="inner-whytext">
              <text>دسترسی لحظه‌ای به آمار فروش و درآمد و امکان تسویه زود هنگام
              </text>
            </div>
          </div>
          <div className={`why-culomn ${y >= 142 ? "tranlate-XClass1050" : null} ${y >= 182 ? "tranlate-XClass850" : null} ${y >= 0 ? "tranlate-XClass650" : null}`}>
            <div className="inner-whyicon">
              <i className={"fa fa-swatchbook"}></i>
            </div>
            <div className="inner-whytitle">
              <h5 style={{marginBottom: 0, fontSize: 16}}>امکان ارسال توسط ماشین نو و فروشنده
              </h5>
            </div>
            <div className="inner-whykhati"></div>
            <div className="inner-whytext">
              <text>بسته‌بندی و ارسال را خودتان انجام دهید یا به ماشین نو بسپارید
              </text>
            </div>
          </div>
          <div className={`why-culomn ${y >= 142 ? "tranlate-XClass1050" : null} ${y >= 182 ? "tranlate-XClass850" : null} ${y >= 123 ? "tranlate-XClass650" : null}`}>
            <div className="inner-whyicon">
              <i className={"fa fa-user-clock"}></i>
              {/*<FaUserClock size={30}/>*/}
            </div>
            <div className="inner-whytitle">
              <h5 style={{marginBottom: 0, fontSize: 16}}>ثبت نام رایگان به همراه آموزش
              </h5>
            </div>
            <div className="inner-whykhati"></div>
            <div className="inner-whytext">
              <text>ثبت نام و استفاده رایگان از پنل فروشندگان به همراه آموزش
              </text>
            </div>
          </div>
          <div className={`why-culomn ${y >= 280 ? "tranlate-XClass1050" : null} ${y >= 350 ? "tranlate-XClass850" : null} ${y >= 270 ? "tranlate-XClass650" : null}`}>
            <div className="inner-whyicon">
              <i className={"fa fa-street-view"}></i>

              {/*<FaStreetView size={30}/>*/}
            </div>
            <div className="inner-whytitle">
              <h5 style={{marginBottom: 0, fontSize: 16}}>تبلیغات رایگان برای بازدید بیشتر محصول شما
              </h5>
            </div>
            <div className="inner-whykhati"></div>
            <div className="inner-whytext">
              <text>ماشین نو بوسیله تبلیغات کلیکی فروش شما را افزایش می‌دهد
              </text>
            </div>
          </div>
          <div className={`why-culomn ${y >= 280 ? "tranlate-XClass1050" : null} ${y >= 350 ? "tranlate-XClass850" : null} ${y >= 490 ? "tranlate-XClass650" : null}`}>
            <div className="inner-whyicon">
              <i className={"fa fa-headset"}></i>
              {/*<FaHeadset size={30}/>*/}
            </div>
            <div className="inner-whytitle">
              <h5 style={{marginBottom: 0, fontSize: 16}}>فروش آنلاین در هر نقطه از ایران
              </h5>
            </div>
            <div className="inner-whykhati"></div>
            <div className="inner-whytext">
              <text>در تمام هفته، 24 ساعته سفارش دریافت کنید و بفروشید
              </text>
            </div>
          </div>
        </div>
      </div>
      <div className={`how-work ${y >= 400 ? "tranlate-XClass" : null}`}>
        <div className={`how-head ${y >= 580 ? "tranlate-XClass1050" : null} ${y >= 665 ? "tranlate-XClass850" : null} ${y >= 900 ? "tranlate-XClass650" : null}`}>
          <div className="matn-khati">
            <text>
              مراحل شروع کار

            </text>
          </div>
          <div className="khati"></div>
        </div>
        <div className={`how-row ${y >= 696 ? "tranlate-XClass1050" : null} ${y >= 723 ? "tranlate-XClass850" : null} ${y >= 1100 ? "tranlate-XClass650" : null}`}>

          <VerticalTimeLine/>

          <div className="card-body pb-0 dis-vertical">
            <ul className="timeline pt-3">
              <li className="timeline-item pb-4 timeline-item-primary border-left-dashed ">
                                <span className="timeline-indicator-advanced timeline-indicator-primary">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="#48C9B0" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"/>
                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"/>
                                    </svg>

                                </span>
                <div className="timeline-event pb-3">
                  <div className="timeline-header mb-sm-0 mb-3">
                    <h6 className="" style={{fontWeight: "bolder"}}>ثبت نام و تکمیل مدارک</h6>

                  </div>

                </div>
              </li>
              <li className="timeline-item pb-4 timeline-item-success border-left-dashed">
                                <span className="timeline-indicator-advanced timeline-indicator-success">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="#5499C7" className="bi bi-camera-video" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z"/>
                                    </svg>

                                </span>
                <div className="timeline-event pb-3">
                  <div className="timeline-header mb-sm-0 mb-3">
                    <h6 className="" style={{fontWeight: "bolder"}}>آموزش استفاده از پنل </h6>

                  </div>


                </div>
              </li>
              <li className="timeline-item pb-4 timeline-item-danger border-left-dashed">
                                <span className="timeline-indicator-advanced timeline-indicator-danger">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="#F4D03Fs" className="bi bi-bag-plus" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5"/>
                                        <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
                                    </svg>

                                </span>
                <div className="timeline-event pb-3">
                  <div className="timeline-header mb-sm-0 mb-3">
                    <h6 className="" style={{fontWeight: "bolder"}}> ثبت محصول و ایجاد فروشگاه</h6>

                  </div>

                </div>
              </li>
              <li className="timeline-item pb-4 timeline-item-info border-left-dashed">
                                <span className="timeline-indicator-advanced timeline-indicator-info">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="#5D6D7E" className="bi bi-save2" viewBox="0 0 16 16">
                                        <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v4.5h2a.5.5 0 0 1 .354.854l-2.5 2.5a.5.5 0 0 1-.708 0l-2.5-2.5A.5.5 0 0 1 5.5 6.5h2V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1z"/>
                                    </svg>

                                </span>
                <div className="timeline-event pb-3">
                  <div className="timeline-header mb-sm-0 mb-3">
                    <h6 className="" style={{fontWeight: "bolder", marginBottom: 10}}>آغاز فروش در ماشین نو</h6>

                  </div>

                </div>
              </li>
            </ul>
          </div>

        </div>
      </div>
      <div className={`re-ju ${y >= 860 ? "tranlate-XClass" : null}`}>
        <div className={`reju-head ${y >= 1025 ? "tranlate-XClass1050" : null} ${y >= 1450 ? "tranlate-XClass650" : null}`}>
          <div className="matn-khati">
            <text>
              مدارک مورد نیاز برای ثبت‌نام

            </text>
          </div>
          <div className="khati"></div>
        </div>
        <div className={`reju-what ${y >= 1135 ? "tranlate-XClass1050" : null}`}>
          <div className={`reju-item ${y >= 1570 ? "tranlate-XClass650" : null}`}>
            <div className="titr-icon">
              <div>
                <i className={"fa fa-male"}></i>
              </div>
              <div>
                <div className="in-pan" onClick={reJuForward}>فرد حقیقی</div>
              </div>
            </div>
            <div className="under-matn">تصویر کارت ملی
            </div>

          </div>
          <div className={`reju-line ${y >= 1756 ? "tranlate-XClass650" : null}`}></div>
          <div className={`reju-item ${y >= 1851 ? "tranlate-XClass650" : null}`}>
            <div className="titr-icon">
              <i className={"fa fa-city"}></i>
              {/*<div><FaCity size={40}/></div>*/}
              <div>
                <div className="in-pan" onClick={reJuForward}>فرد حقوقی</div>
              </div>
            </div>
            <div className="under-matn">تصاویر ثبت‌نام در وب‌سایت «evat.ir»، روزنامه رسمی شرکت و کارت ملی صاحبین امضا
            </div>

          </div>

        </div>
      </div>
      <div className={`question ${y >= 1320 ? "tranlate-XClass tranlate-YClass" : null} ${y >= 1280 ? "tranlate-XClass tranlate-YClass1050" : null} ${y >= 2070 ? "tranlate-YClass650" : null}`}>
        <div>
        </div>
        <div className="col-md mb-4 mb-md-2" style={{width: "98%"}}>
          <small className='fw-medium' style={{fontSize: 18}}>سوالات متداول</small>
          <div className="accordion mt-3" id="accordionExample">
            <div className={`card accordion-item baby ${y >= 2100 ? "tranlate-YClass650" : null} `}>
              <h2 className="accordion-header" id="headingOne">
                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionOne" aria-expanded="false" aria-controls="accordionOne">
                  شرایط ثبت نام فروشنده در ماشین نو چیست؟
                </button>
              </h2>
              <div id="accordionOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample" style={{}}>
                <div className="accordion-body">
                  تمامی اشخاص حقیقی و حقوقی که تولیدکننده، بازرگان یا دارنده کالا باشند می‌توانند به عنوان فروشنده در سایت ماشین نو ثبت‌نام نمایند و پس از احراز هویت و دریافت پنل فروشندگان کالای خود را در معرض فروش قرار دهند. لازم به ذکر است کارکرد این پنل بسیار ساده است و نیازی به تخصص خاصی ندارد.
                </div>
              </div>
            </div>
            <div className={`card accordion-item baby ${y >= 2150 ? "tranlate-YClass650" : null} `}>
              <h2 className="accordion-header" id="headingTwo">
                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionTwo" aria-expanded="false" aria-controls="accordionTwo">
                  برای ثبت‌نام چه مدارکی نیاز است؟
                </button>
              </h2>
              <div id="accordionTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style={{}}>
                <div className="accordion-body">
                  برای فروشنده‌ی حقیقی: تصویر کارت ملی
                  برای فروشنده‌ی حقوقی: تصاویر ثبت‌نام در وب‌سایت «evat.ir»، روزنامه رسمی شرکت و کارت ملی صاحبین امضا

                </div>
              </div>
            </div>
            <div className={`card accordion-item baby ${y >= 2205 ? "tranlate-YClass650" : null} `}>
              <h2 className="accordion-header" id="headingThree">
                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionThree" aria-expanded="false" aria-controls="accordionThree">
                  در مورد قرارداد سوال دارم!!
                </button>
              </h2>
              <div id="accordionThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" style={{}}>
                <div className="accordion-body">
                  بعد از تایید ثبت‌نام یک دوره آموزش در اختیار شما قرار می‌گیرد، در صورت مدیریت صحیح پنل همکاری بسیار فروش در ماشین نو ساده خواهد بود. لازم به ذکر است تا قبل از آغاز اولین فروش هیچگونه تعهد قانونی شامل حال شما نمی‌شود.
                </div>
              </div>
            </div>
            <div className={`card accordion-item baby ${y >= 2255 ? "tranlate-YClass650" : null} `}>
              <h2 className="accordion-header" id="headingThree">
                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionFour" aria-expanded="false" aria-controls="accordionFour">
                  برای ارسال سفارش چه کاری باید انجام دهم؟
                </button>
              </h2>
              <div id="accordionFour" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" style={{}}>
                <div className="accordion-body">
                  پس از ثبت سفارش توسط مشتری، برای شما پیامکی حاوی مراجعه به پنل کاربر ارسال خواهد شد که می‌توانید سفارش‌های ثبت شده را مشاهده و انجام دهید.
                </div>
              </div>
            </div>
            <div className={`card accordion-item baby ${y >= 2310 ? "tranlate-YClass650" : null} `}>
              <h2 className="accordion-header" id="headingThree">
                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionFine" aria-expanded="false" aria-controls="accordionFine">
                  آیا وارد کردن کد رهگیری مالیاتی اجبار است ؟
                </button>
              </h2>
              <div id="accordionFine" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" style={{}}>
                <div className="accordion-body">
                  برای اشخاص حقوقی، بله!
                </div>
              </div>
            </div>
            <div className={`card accordion-item baby ${y >= 2365 ? "tranlate-YClass650" : null} `}>
              <h2 className="accordion-header" id="headingThree">
                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionSix" aria-expanded="false" aria-controls="accordionSix">
                  تسویه حساب ماشین نو با تامین‌کنندگان چگونه است؟
                </button>
              </h2>
              <div id="accordionSix" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" style={{}}>
                <div className="accordion-body">
                  تمامی کالاهای فروخته شده ۱ روز کاری پس از تحویل کالا توسط مامور ارسال به مشتری تسویه خواهد شد.
                </div>
              </div>
            </div>
            <div className={`card accordion-item baby ${y >= 2410 ? "tranlate-YClass650" : null} `}>
              <h2 className="accordion-header" id="headingThree">
                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionSeven" aria-expanded="false" aria-controls="accordionSeven">
                  نام فروشگاه یا فروشنده چیست؟

                </button>
              </h2>
              <div id="accordionSeven" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" style={{}}>
                <div className="accordion-body">
                  نام تجاری به معنای نامی است که فروشنده برای پنل فروش خود انتخاب می‌نماید و می‌بایست نامی به غیر از نام اصلی فروشنده یا مغازه وی باشد.
                </div>
              </div>
            </div>


          </div>
        </div>

      </div>


    </div>


  );
}

export default Welcome;
