import {Dispatch} from "react";

const STATUS_OK = 200;
const STATUS_VENDOR_NOT_FOUND = 421;
const STATUS_TOKEN_EXPIRE = 422;
const STATUS_VALIDATION_ERROR = 501;
const STATUS_ERROR = 500;
const STATUS_HAS_NO_ACCESS = 403;

const ERROR_MESSAGES = {
  STATUS_TOKEN_EXPIRE: "خطا در احراز هویت",
  STATUS_VALIDATION_ERROR: "خطای اطلاعات ورودی",
  STATUS_ERROR: "خطای نامشخص",
  STATUS_HAS_NO_ACCESS: "Unauthorized"
}

export interface ApiResponse {
  status: number,
  success: boolean,
  payload: any,
  message?: string
}


export function validateResponse(response: ApiResponse, isFromMainFile = false) {

  if (response.status === STATUS_OK) {
    return response;
  }

  if (isFromMainFile) {
    throw new ResponseException("خطایی رخ داده است لطفا مجددا تلاش کنید", STATUS_ERROR)
  }

  if (response.status !== STATUS_TOKEN_EXPIRE) {
    throw new ResponseException(response.message)
  }

}

export function validateAuth(response: ApiResponse, dispatcher: any| null = null) {


  if (response.status === STATUS_TOKEN_EXPIRE) {
    throw new AuthException(dispatcher)
  }

  if (response.status === STATUS_VENDOR_NOT_FOUND) {
    throw new AuthException()
  }

  return response;
}


export function responseErrorHandler() {
  return {
    props: {
      status: STATUS_ERROR,
      error: true
    }
  }
}


export class ResponseException {
  message;
  status;

  constructor(message?: string, status = 500) {
    this.message = message
    this.status = STATUS_VALIDATION_ERROR
  }
}


export class AuthException {
  constructor(dispatch: any | null = null) {
    if (dispatch) {
      dispatch({
        type: "on-login",
        payload: {
          auth: null,
          isVendor: false,
          isApproved: false,
          vendor: null,
          registration: {
            type: null,
            page: "login"
          }
        }
      })
      window.location.href = "/auth"
    }else{
      window.location.href = "/auth"
    }

  }
}

