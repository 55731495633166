import React, {useEffect, useState} from 'react';
import ProductPage from "./ProductPage";
import {webRequest} from "../../../Infrastructure/services/apiService";
import useAppContext from "../../../Infrastructure/state/useAppContext";
import {validateAuth} from "../../../Infrastructure/services/validateResponse";

let result: any = window.localStorage.getItem("p_page_default_data")

let defaultBrands: any[] = []
let defaultCars: any[] = []

try {
  result = JSON.parse(result)
  defaultBrands = result.brands
  defaultCars = result.cars
} catch (e) {
}


const Index = ({type}: { type: string }) => {

  let [brands, setBrands] = useState(defaultBrands)
  let [cars, setCars] = useState(defaultCars)
  let [categories, setCategories] = useState([])
  let [loaded, setLoaded] = useState(false)
  let {state, dispatch} = useAppContext()

  useEffect(() => {

    if (!loaded) {


      setLoaded(true)
      webRequest({
        url: "/get-brand-and-cars",
        auth: state.auth
      }).then(response => {
        try {
          validateAuth(response, dispatch)
          window.localStorage.setItem("p_page_default_data", JSON.stringify(response.payload))
          setBrands(response.payload.brands)
          setCars(response.payload.cars)
          setCategories(response.payload.categories)
        } catch (e: any) {

        }

      })
    }


  }, []);


  return (
    <div className={"card p-2"}>
      <ProductPage brands={brands} type={type} cars={cars} categories={categories}/>
    </div>
  );
};

export default Index;