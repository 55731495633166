import React, {useEffect, useState} from 'react';
import {webRequest} from "../../../Infrastructure/services/apiService";
import DesktopSkeleton from "../ProductPage/DesktopSkeleton";
import MobileSkeleton from "../ProductPage/MobileSkeleton";
import useAppContext from "../../../Infrastructure/state/useAppContext";

const TutorialsPage = () => {

  let [records, setRecords] = useState([])
  let [loaded, setLoaded] = useState(false);
  let {state, dispatch} = useAppContext()
  let [width, setWidth] = useState(window.innerWidth)


  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));

    webRequest({
      url: "/tutorials",
      auth:state.auth
    }).then(response => {
      let result = response.payload
      setRecords(result)
      setLoaded(true)
    })

  }, []);


  return (
    <div className="card">

      <div className={"card-header"} style={{borderBottom: "1px solid #eee"}}>
        آموزش استفاده از پنل
      </div>

      <div className={"card-body"}>
        <div className={"rounded p-2"}>
          {records.map((row: any, index: number) => {
            return <div className={"col-md-3 col-sm-6"} key={index} style={{border: "1px solid #eee", boxShadow: "0 0 7px 0 #eee", padding: "8px", display: "inline-block", margin: "8px"}}>
              <video src={"https://dash.mashinno.com/" + row.videoPath} controls={true} style={{width: "100%", aspectRatio: "16/9"}}></video>
              <div className={"tac"}>{row.title}</div>
            </div>
          })}
        </div>
      </div>
    </div>
  );
};

export default TutorialsPage;