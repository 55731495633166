
import "./content.css"
import useAppContext from "../../../../Infrastructure/state/useAppContext";
import {dismissConfirmDialog, showConfirmDialog} from "../../../../hooks/confirmDialog";

interface Props {
  children: React.ReactNode,
  title: string,
  footer: React.ReactNode,
}

const RegisterContent = (props: Props) => {

  let { dispatch} = useAppContext()

  const logout = () => {
    showConfirmDialog({
      title: "از مراحل ثبت نام خارج می‌شوید؟",
      height: 180,
      description: " اطلاعات شما ذخیره خواهد شد و می‌توانید هر زمان ثبت نام خود را ادامه دهید. ",
      onConfirmButtonClicked: () => {
        dispatch({
          type: "on-login",
          payload: {
            auth: null,
            isVendor: false,
            isApproved:false,
            vendor: null,
            registration: {
              type: null,
              page: "login"
            }
          }
        })
        dismissConfirmDialog()
      }
    })
  }


  return (
    <>
      <div className={"header"}>
        <div>
          {props.title}
        </div>
        <div className={"time-container"} onClick={logout}>
          <i className={"ti ti-x"}></i>
        </div>
      </div>
      <div className="content">
        {props.children}
      </div>

      <div className={"footer"} style={{background: "#fff"}}>
        {props.footer}
      </div>
    </>
  )
    ;
};

export default RegisterContent;