import React from 'react';
import {ProductLite} from "../../../Infrastructure/services/Types/Product";
import {numberFormat} from "../../../commons/numberHelper";
import {dismissConfirmDialog, showConfirmDialog} from "../../../hooks/confirmDialog";
import {webRequest} from "../../../Infrastructure/services/apiService";
import {validateAuth, validateResponse} from "../../../Infrastructure/services/validateResponse";
import useAppContext from "../../../Infrastructure/state/useAppContext";
import xToast from "../../../commons/xToast";

interface Props {
  row: ProductLite,
  onRefresh:Function
  // onDelete: Function,
  // onAdd: Function,
  onAddPrice: Function,
}


const MobileMenu = (props: Props) => {
  let [show, setShow] = React.useState(false);

  let {state,dispatch}=useAppContext()

  let row = props.row

  const menuClicked = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setShow(!show);
  }

  let priceText = "بدون قیمت"

  if (row.priceCount === 1) {
    priceText = numberFormat(row.price) + " تومان"
  } else if (row.priceCount > 1) {
    if (row.price === row.lastPrice) {
      priceText = numberFormat(row.price) + " تومان"
    } else {
      priceText = "از " + numberFormat(row.price) + " تا " + numberFormat(row.lastPrice) + " تومان "
    }
  }

  const onAddProduct = (id:any) => {

    showConfirmDialog({
      title: "افزودن محصول",
      height: 180,
      description: "محصول به لیست محصولات من اضافه شود؟ ",
      onConfirmButtonClicked: () => {
        let toast = xToast.loading("در حال افزودن محصول")
        webRequest({
          url: "/products/add",
          data: {
            ids: [id],
            mode: "add"
          },
          auth: state.auth
        }).then(response => {
          try {
            validateAuth(response,dispatch)
            validateResponse(response)
            toast.updateAndDismiss("success", "عملیات با موفقیت انجام شد", 3000)
            props.onRefresh()

          } catch (e: any) {
            toast.updateAndDismiss("error", e.message, 3000)
          }

        })

        dismissConfirmDialog()
      }
    })
  }

  const onDeleteClicked = (id:any) => {

    showConfirmDialog({
      title: "حذف محصول؟",
      height: 180,
      description: "با حذف این محصول همه قیمت های آن نیز حذف خواهند شد، ادامه می دهید؟ ",
      onConfirmButtonClicked: () => {
        let toast = xToast.loading("در حال حذف محصول")
        webRequest({
          url: "/products/add",
          data: {
            ids: [id],
            mode: "delete"
          },
          auth: state.auth
        }).then(response => {
          try {
            validateResponse(response)
            toast.updateAndDismiss("success", "عملیات با موفقیت انجام شد", 3000)
            props.onRefresh()

          } catch (e: any) {
            toast.updateAndDismiss("error", e.message, 3000)
          }

        })

        dismissConfirmDialog()
      }
    })
  }


  return (
    <div className="product-card row" key={row.id} style={{position: "relative"}} onClick={() => setShow(false)}>
      <span className={"ti ti-dots-vertical"} onClick={menuClicked} style={{position: "absolute", display: "inline-block", width: "32px", height: "32px", cursor: "pointer", left: "12px", top: "16px", textAlign: "center",zIndex:4}}>
        {row.isInMyProduct && <i className={"ti ti-check"} style={{position: "absolute", top: "-6px", left: "4px", fontSize: "14px"}}></i>}
      </span>

      {show && <div style={{position: "absolute", left: "32px", top: 8, width: "200px", zIndex: "100", background: "#fff", boxShadow: "0 0 7px 0 #eee", borderRadius: "12px", border: "1px solid #eee"}}>
        {!row.isInMyProduct && <div style={{fontSize: "13px", cursor: "pointer", borderBottom: "1px solid #eee", lineHeight: "32px"}} onClick={() => onAddProduct(row.id)}> افزودن به محصولات من</div>}
        {row.isInMyProduct && <div style={{fontSize: "13px", cursor: "pointer", lineHeight: "32px"}} onClick={() => onDeleteClicked(row.id)}> حذف از محصولات من</div>}
        {row.isInMyProduct && <div style={{fontSize: "13px", cursor: "pointer", lineHeight: "32px",borderTop:"1px solid #eee"}} onClick={() => props.onAddPrice(props.row)}>لیست قیمت های محصول</div>}
      </div>}



      <div className={"col-4"}>
        <img src={"https://mashinno.com/" + row.image} alt="" style={{borderRadius:"12px"}}/>
      </div>

      <div className="col-8" style={{position: "relative"}}>
        <div className="title" style={{width:"calc(100% - 32px)"}}>
          {row.title}
        </div>
        {row.barcode && <div style={{color: "#222", fontSize: "13px", marginBottom: "4px"}}>
          کد فنی
          :
          {row.barcode}
        </div>}
        <div style={{fontSize: "13px", color: "#222"}}>
          تعداد قیمت ها :
          &nbsp;
          {row.priceCount}
        </div>
        <div style={{fontSize: "13px", color: "#049775", marginTop: "4px"}}>
          قیمت :
          &nbsp;
          {priceText}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;