import React, {useState} from 'react';
import UserSkeleton from "./UserSkeleton";

const Users = ({users, clicked, addNew}: { addNew: Function, users: any, clicked: Function }) => {

  let [active, setActive] = useState(-1);

  const userClicked = (user: any) => {
    clicked(user);
    setActive(user.id)
  }


  return (
    <div className="sidebar-body">


      <ul className="list-unstyled chat-contact-list mb-0" id="contact-list">

        <li className={"chat-contact-list-item p-0 mb-2 mt-2"}>
            <span className={"btn btn-primary"} style={{width: "100%", height: "48px"}} onClick={() => addNew()}>
              گفت و گو جدید
            </span>
        </li>

        {!users && <>
          <UserSkeleton/>
          <UserSkeleton/>
          <UserSkeleton/>
          <UserSkeleton/>
          <UserSkeleton/>
          <UserSkeleton/>
          <UserSkeleton/>
          <UserSkeleton/>
          <UserSkeleton/>
          <UserSkeleton/>
        </>}

        {users && <>
          {users.map((user: any, index: number) => {
            return <li key={index} className={`chat-contact-list-item ${active == user.id ? "active" : ""}`} onClick={() => userClicked(user)}>
              <a className="d-flex align-items-center">
                <div className="avatar d-block flex-shrink-0">
                <span className="avatar-initial rounded-circle bg-label-primary" style={{borderRadius: "100%", padding: "1px"}}>
                  <img src="/assets/img/avatar.png" alt=""/>
                </span>
                </div>
                <div className="chat-contact-info flex-grow-1 ms-2">
                  <h6 className="chat-contact-name text-truncate m-0">{user.subject}</h6>
                  <p className="chat-contact-status text-muted text-truncate mb-0">{user.message}</p>
                </div>
              </a>
            </li>
          })}
        </>}


      </ul>
      {users && users.length == 0 && <div className={"tac pt-5"}>
        گفت و گویی ثبت نشده است
      </div>}

    </div>

  );
};

export default Users;