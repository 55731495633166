import React from 'react';

const ChatHeader = ({ticket}: { ticket: any }) => {

  let closeTicket = () => {

  }


  return (
    <div className="chat-history-header border-bottom bg-white">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex overflow-hidden align-items-center">
          <i className="ti ti-menu-2 ti-sm cursor-pointer d-lg-none d-block me-2" data-bs-toggle="sidebar" data-overlay data-target="#app-chat-contacts"/>
          <div className="flex-shrink-0 avatar">
            <img alt="آواتار" style={{borderRadius:"100%",border:"1px solid #eee"}} className="rounded-circle" data-bs-toggle="sidebar" data-overlay data-target="#app-chat-sidebar-right" src="/assets/img/avatar.png"/>
          </div>
          <div className="chat-contact-info flex-grow-1 ms-2">
            {ticket && <h6 className="m-0">{ticket.subject}</h6>}
            {ticket && <small className="user-status text-muted">{ticket.updatedAtText}</small>}
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="dropdown d-flex align-self-center">
            <button aria-expanded="false" aria-haspopup="true" className="btn p-0" data-bs-toggle="dropdown" id="chat-header-actions" type="button">
              <i className="ti ti-dots-vertical"/>
            </button>
            <div aria-labelledby="chat-header-actions" className="dropdown-menu dropdown-menu-end">
              <span className="dropdown-item cp" onClick={closeTicket}>بستن تیکت</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;