import React, {useEffect, useState} from 'react';
import Loading from "../../Layouts/Loading/Loading";
import {webRequest} from "../../../Infrastructure/services/apiService";
import {useNavigate, useParams} from "react-router-dom";
import {validateResponse} from "../../../Infrastructure/services/validateResponse";
import xToast from "../../../commons/xToast";
import useAppContext from "../../../Infrastructure/state/useAppContext";

const TokenLogin = () => {

  let params = useParams()
  let {state, dispatch} = useAppContext()

  let [loading, setLoading] = useState(true);
  let navigate = useNavigate()


  useEffect(() => {
    let token = params.token
    webRequest({
      url: "/profile/tokenLogin",
      data: {
        token
      },
    }).then(response => {
      try {
        validateResponse(response)

        if (response.payload.mode == "found") {
          dispatch({
            type: "on-login",
            payload: response.payload.auth
          })
          navigate("/auth")
        } else {
          setLoading(false)
        }
      } catch (e: any) {
        xToast.error(e.message)
      }

    })
  }, []);

  return (
    <div>
      <div className={"container ma"}>
        <div style={{maxWidth: "1200px", marginTop: "48px", width: "calc(100% - 32px)", marginLeft: "auto", marginRight: "auto"}}>
          <div className={"card mt-3"} style={{padding: "8px"}}>
            {loading && <Loading text={"در حال بررسی توکن ، لطفا صبر کنید."}/>}
            {!loading && <div style={{textAlign:"center"}}>
              <div>
                <i className={"ti ti-face-id-error"} style={{fontSize:"70px"}}></i>
              </div>
              خطایی رخ داده است
              <div>توکن  منقضی شده  یا اشتباه است</div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenLogin;