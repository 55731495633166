import React from 'react';
import Skeleton from "react-loading-skeleton";

const UserSkeleton = () => {
  return (
    <li className={"chat-contact-list-item"} style={{display: "flex",alignItems:"center",justifyContent:"flex-start"}}>
      <Skeleton width={48} height={48} borderRadius={"100%"} />
      <div style={{marginRight:"16px",paddingTop:"4px"}}>
        <Skeleton width={200} height={10}/>
        <Skeleton width={100} height={10}/>
      </div>
    </li>
  );
};

export default UserSkeleton;