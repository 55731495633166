import "./input.css"
import React, {useRef} from 'react';
import {getOnlyNumbers, numberFormat} from "../../../commons/numberHelper";


interface Props {
  label: string,
  value: string,
  setValue: Function,
  type?: string,
  center?: boolean,
  parentClass?: string,
  autoCompleteOff?: boolean,
  hint?: string | null | undefined,
  onEnter?: Function,
}

const TextArea = (props: Props) => {

  let inputRef: any = useRef()
  let type = "text"
  if (props.type) type = props.type;
  if (props.type === "number") type = "tel"


  let center = false
  if (props.center) center = props.center;

  let parentClass = props.parentClass
  if (!parentClass) parentClass = ""


  const changed = (e: any) => {
    let value = e.target.value
    if (props.type === "number") value = getOnlyNumbers(value)
    props.setValue(value)
  }

  const initKeyup = (event: any) => {
    if (event.key === "Enter") {
      if (props.onEnter) {
        props.onEnter(event)
      }
    }
  }


  const labelClicked = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }


  let value = props.value ?? 0

  if (props.type === "number") {
    value = numberFormat(value)
  }

  return (
    <div className={`${parentClass}`} style={{height:"200px"}}>
      <div className="input-wrapper">
        <textarea ref={inputRef}  className={`input input-text ${center ? "tac" : ""}`} autoComplete="off" placeholder="" required value={value} onKeyUp={initKeyup} onChange={(e) => changed(e)} style={{height:"200px"}}/>
        <label onClick={labelClicked} className="input-label">{props.label}</label>
      </div>
      {props.hint && <div className={"input-hint"}>
        {props.hint}
      </div>}
    </div>
  );
};

export default TextArea;