import React, {useEffect, useState} from 'react';
import {Outlet} from "react-router-dom";
import useAppContext from "../../Infrastructure/state/useAppContext";

const AuthenticatedComponent = () => {
  let {state} = useAppContext()

  let [loaded, setLoaded] = useState(false);

  useEffect(() => {
    try {
      let user = state.auth!.user
      if (user && state.isVendor) {
        if (!state.confirmedVendor){
          window.location.href="/auth"
          return;
        }
        setLoaded(true)
      } else {
        throw "auth error"
      }
    } catch (e: any) {
      setLoaded(false)
      window.location.href = "/auth"
    }

  }, [state.auth]);

  return (
    <div>
      {loaded && <Outlet/>}
    </div>
  );
};

export default AuthenticatedComponent;