import React, {useEffect, useReducer} from 'react';
import reducer from "./reducer";
import initState from "./initState";
import AppContext from "./appContext";


const StateProvider = ({children}: { children: React.ReactNode }) => {

  const [state, dispatch] = useReducer(reducer, initState)


  useEffect(() => {
    dispatch({
      type: "DISPATCH_STATE",
      payload: []
    })

    //
    // callEvent("public-events",null)
    // callEvent("app-public-dispatch")
    //

    // Router.events.on("routeChangeStart", (url)=>{
    //   NProgress.start()
    // });
    //
    // Router.events.on("routeChangeComplete", (url)=>{
    //   NProgress.done(false)
    // });
  }, []);

  return (
    <AppContext.Provider value={{state, dispatch}}>
      {children}
    </AppContext.Provider>
  );
}

export default StateProvider;
