import React, {useEffect} from 'react';
import {webRequest} from "../../../Infrastructure/services/apiService";

const NotificationsPage = () => {
  useEffect(() => {
    webRequest({
      url: "/notifications"
    }).then(response => {
      console.log(response)
    })
  }, []);
  return (
    <div className={"table-responsive"}>
      <table className={"table table-bordered"}>
        <thead>
        <tr>
          <td>متن پیام</td>
          <td>تاریخ</td>
          <td>عملیات</td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default NotificationsPage;