import React, {useEffect, useRef, useState} from 'react';
import Dialog from "../../../Layouts/Dialog/Dialog";
import {IProductType, ProductLite} from "../../../../Infrastructure/services/Types/Product";
import Input from "../../../Ui/Input/Input";
import {ISelectType} from "../../../../Infrastructure/types/ISelectType";
import XSelect from "../../../Ui/Select/XSelect";
import {webRequest} from "../../../../Infrastructure/services/apiService";
import Checkbox from "../../../Ui/Checkbox";
import xToast from "../../../../commons/xToast";
import {validateNumber, validateSelect} from "../../../../commons/public-validation";
import useAppContext from "../../../../Infrastructure/state/useAppContext";
import {validateAuth, validateResponse} from "../../../../Infrastructure/services/validateResponse";
import Skeleton from "react-loading-skeleton";

interface Props {
  onCancel: Function,
  onConfirm: Function,
  product: ProductLite | null
}

const AddPriceDialog = (props: Props) => {


  let {state} = useAppContext()
  let [mainPrice, setMainPrice] = useState("")
  let [finalPrice, setFinalPrice] = useState("")
  let [quantity, setQuantity] = useState("")

  let fileRef: any = useRef(null);

  let [brands, setBrands] = useState<ISelectType[]>([])
  let [brand, setBrand] = useState<ISelectType | null>(null)

  let [countries, setCountries] = useState<ISelectType[]>([])
  let [country, setCountry] = useState<ISelectType | null>(null)

  let [warranties, setWarranties] = useState<ISelectType[]>([])
  let [warranty, setWarranty] = useState<ISelectType | null>(null)

  let [validities, setValidities] = useState<ISelectType[]>([])
  let [validity, setValidity] = useState<ISelectType | null>(null)

  let [description, setDescription] = useState<string>("")

  let [loaded, setLoaded] = useState(false)

  let [vendorSend, setVendorSend] = useState(false)
  let [isStock, setIsStock] = useState(false)
  let [hasTax, setTax] = useState(false)
  let [types, setTypes] = useState<[]>([])

  let [canAdd, setCanAdd] = useState(true)

  let [typeSelected, setTypeSelected] = useState<ISelectType | null>(null)

  let cancel = () => {
    props.onCancel()
  }


  let [images, setImages] = useState<string[]>([])

  useEffect(() => {
    if (!props.product) {
      return;
    } else {
      setMainPrice("")
      setFinalPrice("")
      setQuantity("")
      setDescription("")
      setVendorSend(false)
      setTax(false)
      setIsStock(false)
      setImages([])
      setTypeSelected(null)
      setWarranty(null)
      setBrand(null)
      setCountry(null)
      setValidity(null)

    }
    getData()
  }, [props.product]);


  let getData = () => {
    try {

      if (!loaded) {
        webRequest({
          url: "/get-add-price-data",
          auth: state.auth
        }).then(response => {
          try {
            validateAuth(response)
            validateResponse(response)
            setBrands(response.payload.brands)
            setCountries(response.payload.countries)
            setWarranties(response.payload.waranties)
            setValidities(response.payload.validities)
            setTypes(response.payload.types)
            setLoaded(true)
          } catch (e: any) {

          }

        })
      }
    } catch (e) {
    }
  }


  const addImage = () => {
    if (images.length >= 4) {
      xToast.error("امکان آپلود چهار مورد بیشتر وجود ندارد")
      return;
    }

    if (fileRef.current) {
      const newFileRef = fileRef.current.cloneNode(true);
      fileRef.current.parentNode.replaceChild(newFileRef, fileRef.current);
      fileRef.current = newFileRef;
    }

    fileRef.current.click();

    const handleFileChange = (event: any) => {
      let fileReader = new FileReader();
      fileReader.onloadend = () => {
        setImages((prevImages: any) => [...prevImages, fileReader.result]);
      };
      if (event.target.files[0]) {
        fileReader.readAsDataURL(event.target.files[0]);
      }
    };

    fileRef.current.addEventListener("change", handleFileChange);
  };

  const confirm = () => {
    if (!canAdd) return;
    try {
      validateNumber(mainPrice, "قیمت اصلی", {min: 2000, max: 100_000_000})
      if (finalPrice && finalPrice.length > 0)
        validateNumber(finalPrice, "قیمت تخفیف خورده", {min: 2000, max: 100_000_000})
      validateNumber(quantity, "تعداد موجودی", {min: 1, max: 1000})
      validateSelect(brand, "برند تولید کننده")
      validateSelect(country, "کشور سازنده")
      validateSelect(warranty, "گارانتی")
      validateSelect(validity, "اعتبار قیمت")

      let toast = xToast.loading("در حال ذخیره قیمت")

      setCanAdd(false)
      webRequest({
        url: "/price-add",
        auth: state.auth,
        data: {
          mainPrice, finalPrice, quantity, images, type: typeSelected, id: props.product!.id,
          brand, country, warranty, description, validity, hasTax, vendorSend, isStock
        }
      }).then(response => {
        try {
          validateResponse(response)
          if (response.payload) {
            toast.updateAndDismiss("success", response.payload.message, 3000)
            props.onConfirm()

          }
        } catch (e: any) {
          toast.updateAndDismiss("error", e.message)
        }

        setCanAdd(true)
      }).catch(() => {
        setCanAdd(true)
      })


    } catch (e: any) {
      xToast.error(e.message)
    }

    // validateArray(brand)
  }

  let hasType = false
  let type: any | null = null
  let lines: ISelectType[] = []

  if (props.product?.type.has && loaded) {
    hasType = true
    type = props.product.type.data
    type = types.filter((row: any) => row.id === type!.id)[0]

    // eslint-disable-next-line array-callback-return
    type.lines.map((row: any) => {
      lines.push({
        label: row.data,
        value: row.id
      })
    })
  }

  if (hasType && loaded) {
    console.log(type!.lines)
  }
  const removeFromOld = (event: any, index: number) => {
    event.preventDefault()
    event.stopPropagation()
    let newImages = images.filter((row, key) => key !== index)
    setImages(newImages);
  }

  return (
    <>
      {props.product && loaded && <Dialog show={props.product != null} setShow={cancel} height={650} width={700} title={"افزودن قیمت جدید به محصول"}>

        <div className={"row row-small-padding"}>
          <Input type={"number"} center={true} label={"قیمت اصلی"} parentClass={"col-md-6 mt-3"} value={mainPrice} setValue={setMainPrice}/>
          <Input type={"number"} center={true} label={"قیمت تخفیف خورده"} parentClass={"col-md-6 mt-3"} value={finalPrice} setValue={setFinalPrice}/>
        </div>

        <div className={"row row-small-padding mt-3"}>
          <XSelect records={brands} label={"برند تولید کننده"} parentClass={"col-md-4 mt-3"} defaultOption={brand} optionSelected={setBrand}/>
          <XSelect records={countries} label={"کشور سازنده"} parentClass={"col-md-4 mt-3"} defaultOption={country} optionSelected={setCountry}/>
          <XSelect records={warranties} label={"گارانتی"} parentClass={"col-md-4 mt-3"} defaultOption={warranty} optionSelected={setWarranty}/>
        </div>

        <div className={"row row-small-padding mt-3"}>
          <div className={`col-md-${hasType ? 6 : 12}`} style={{marginBottom: "8px"}}>
            <XSelect records={validities} label={"اعتبار قیمت"} parentClass={"col-md-12 "} defaultOption={validity} optionSelected={setValidity}/>
          </div>
          {hasType && <XSelect records={lines} label={type!.title} parentClass={"col-md-6"} defaultOption={typeSelected} optionSelected={setTypeSelected}/>}

        </div>

        <div className={"row row-small-padding mt-3"}>
          <Input center={false} type={"text"} label={"توضیحات ( غیر الزامی )"} parentClass={"col-md-12"} value={description} setValue={setDescription}/>
        </div>


        <div className={"row row-small-padding mt-3"}>


          <div className={"col-md-6"}>
            <Input type={"number"} center={true} label={"تعداد موجودی"} parentClass={"col-md-12"} value={quantity} setValue={setQuantity}/>


            <Checkbox label={"امکان ارسال توسط فروشنده"} parentClass={"mt-4"} checked={vendorSend} setChecked={setVendorSend}/>
            <Checkbox label={"با احتساب ارزش افزوده"} parentClass={"mt-2"} checked={hasTax} setChecked={setTax}/>
            <Checkbox label={"این کالا استوک است"} parentClass={"mt-2 mb-3"} checked={isStock} setChecked={setIsStock}/>
          </div>


          <div className={"col-md-6 "} style={{height: "200px", textAlign: "center", cursor: "pointer"}} onClick={addImage}>
            <div style={{width: "calc(100% - 16px)", border: "1px dashed #aaa", borderRadius: "12px", position: "relative", height: "180px"}} className={"ma"}>
              <div className={"row row-small-padding"} style={{justifyContent: "space-evenly", padding: "8px 12px"}}>
                {images.map((image, index) => {
                  return <div key={index} style={{display: "inline-block", width: "80px", height: "80px", marginLeft: "8px", position: "relative"}}>
                    <i className={"ti ti-trash"} style={{color: "#f44", position: "absolute", top: "4px", width: "24px", height: "24px", display: "inline-block", zIndex: 1111, left: "0", cursor: "pointer"}} onClick={(event: any) => removeFromOld(event, index)}></i>
                    <img src={image} alt="" width={80} style={{borderRadius: "12px"}}/>
                  </div>
                })}
              </div>
              <div style={{position: "absolute", width: "100px", height: "20px", left: "0", right: "0", top: "0", bottom: "0", margin: "auto auto"}}>
                افزودن عکس
              </div>
            </div>
          </div>

        </div>
        <div style={{borderTop: "1px solid  #eee", marginBottom: "8px"}}></div>
        <span className={"btn btn-primary"} onClick={confirm}>تایید و ثبت قیمت</span>

        <input type="file" style={{display: "none"}} ref={fileRef}/>

      </Dialog>}


      {props.product && !loaded && <Dialog show={props.product != null} setShow={cancel} height={650} width={700} title={"افزودن قیمت جدید به محصول"}>

        <div style={{width: "100%", height: "100%", overflow: "hidden"}}>
          <Skeleton width={"100%"} height={"800px"}/>
        </div>

      </Dialog>}
    </>
  )
}

export default AddPriceDialog;