import React, {useEffect, useRef} from 'react';
import useAppContext from "../../../../Infrastructure/state/useAppContext";
import {validateSelect, validateString} from "../../../../commons/public-validation";
import xToast from "../../../../commons/xToast";
import {webRequest} from "../../../../Infrastructure/services/apiService";
import {validateAuth, validateResponse} from "../../../../Infrastructure/services/validateResponse";
import {getOnlyNumbers} from "../../../../commons/numberHelper";
import Input from "../../../Ui/Input/Input";

const EditProfilePage = () => {

    let {state,dispatch} = useAppContext();

    let fileRef: any = useRef();
    let imageRef: any = useRef();

    let [name, setName] = React.useState<string>("");
    let [family, setFamily] = React.useState<string>("");
    let [accountNumber, setAccountNumber] = React.useState<string>("");
    let [image, setImage] = React.useState<string>("");
    let [hasImage, setHasImage] = React.useState(false);
    let [storeName, setStoreName] = React.useState<any>("");

    useEffect(() => {
      getData()
    }, [])


    let getData = () => {

      webRequest({
        url: "/profile/getInfo",
        auth: state.auth
      }).then(res => {
          showData(res)
        }
      )

    }



    const showData = (res: any) => {

      try {
        validateResponse(res)
        validateAuth(res)

        setName(res.payload.name)
        setFamily(res.payload.family)
        setImage(res.payload.avatar)
        setStoreName(res.payload.storeName)
        setAccountNumber(res.payload.accountNumber)

        try {
          if (typeof state.auth?.user?.avatar === "string") {
            setImage("https://mashinno.com/x-api/files/uploads/avatars/" + state.auth?.user?.avatar)
            return;
          }
          setImage("/assets/img/user/avatar.png")
        } catch (e: any) {
          setImage("/assets/img/user/avatar.png")
        }
      } catch
        (e: any) {

      }
    }

    const confirm = () => {
      try {
        validateString(name, "نام", {min: 3, max: 30})
        validateString(family, "نام خانوادگی", {min: 3, max: 30})
        if (image.length < 10) throw new Error("تصویر کارت ملی الزامی است")

        let toast = xToast.loading("در حال ذخیره اطلاعات")

        webRequest({
          url: "/profile/update",
          data: {
            hasImage,
            name, family, accountNumber,
            image,storeName
          },
          auth: state.auth
        }).then(response => {
          try {
            validateResponse(response)
            toast.updateAndDismiss("success", "به روز رسانی با موفقیت انجام شد", 3000)


            dispatch({
              type: "on-login",
              payload:response.payload.user
            })
            window.location.reload();

          } catch (e: any) {
            toast.updateAndDismiss("error", e.message, 3000)
          }
        })


      } catch (e: any) {
        xToast.error(e.message);
      }
    }

    const imageSelected = (event: any) => {
      let fileReader = new FileReader()
      fileReader.onloadend = () => {
        let image: any = fileReader.result
        setImage(image)
        setHasImage(true)
      }
      fileReader.readAsDataURL(event.target.files[0]);
    }

    let _accountNumber = getOnlyNumbers(accountNumber)


    let isValid = () => {
      try {
        validateString(name, "نام", {min: 3, max: 30})
        validateString(family, "نام خانوادگی", {min: 3, max: 30})
        validateString(storeName, "نام فروشگاه", {min: 3, max: 30})
        return true
      } catch (e: any) {
        return false
      }
    }

    let canContinue = isValid()


    return (
      <div className={"card p-3"}>
        <div className={"row row-small-padding mt-3"}>
          <Input parentClass={"col-6"} label={"نام"} center={true} value={name} setValue={setName}/>
          <Input parentClass={"col-6"} label={"نام خانوادگی"} center={true} value={family} setValue={setFamily}/>
        </div>

        <div className={"row row-small-padding mt-3"}>
          <Input parentClass={"col-6"} label={"شماره حساب"} value={_accountNumber} center={true} setValue={setAccountNumber}/>
          <Input parentClass={"col-6"} label={"نام فروشگاه"} value={storeName} center={true} setValue={setStoreName}/>
        </div>


        <input type="file" ref={fileRef} style={{display: "none"}} accept="image/*" onChange={imageSelected}/>

        <div style={{width: '200px', height: "200px", border: "1px dashed #aaa", borderRadius: "12px", margin: "24px auto", textAlign: "center", lineHeight: "200px", cursor: "pointer", position: "relative"}} onClick={() => fileRef.current.click()}>
          <span style={{borderBottom: "1px dashed #aaa"}}>آواتار</span>
          <img ref={imageRef} src={image} alt="" width="180px" height="180px" style={{position: "absolute", left: "0", right: "0", top: "0", bottom: "0", margin: "auto auto", borderRadius: "12px", border: "1px dashed #fff"}}/>
        </div>
        <div onClick={confirm} style={{borderTop: "1px solid #eee", paddingTop: "8px"}}>
          <span className={`btn btn-confirm-footer ${canContinue ? "btn-primary" : "btn-dark"}`}>ثبت و ادامه</span>
        </div>
      </div>
    );
  }
;

export default EditProfilePage;