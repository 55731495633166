import React, {useEffect, useRef} from 'react';
import Input from "../../../../Ui/Input/Input";
import {validateString} from "../../../../../commons/public-validation";
import xToast from "../../../../../commons/xToast";
import {webRequest} from "../../../../../Infrastructure/services/apiService";
import {getOnlyNumbers} from "../../../../../commons/numberHelper";
import useAppContext from "../../../../../Infrastructure/state/useAppContext";
import {validateResponse} from "../../../../../Infrastructure/services/validateResponse";
import RegisterContent from "../../Content/RegisterContent";
import {toast} from "react-toastify";

const CompleteTrueInformation = () => {

  let {state, dispatch} = useAppContext();

  let fileRef: any = useRef();
  let imageRef: any = useRef();

  let [name, setName] = React.useState<string>("");
  let [family, setFamily] = React.useState<string>("");
  let [nationCode, setNationCode] = React.useState<string>("");
  let [mobile, setMobile] = React.useState<string>("");
  let [image, setImage] = React.useState<string>("");

  let [hasImage, setHasImage] = React.useState(false);


  useEffect(() => {
    setTimeout(() => toast.dismiss(), 3000)

    try {
      setName(state.registration.information.data.name);
      setFamily(state.registration.information.data.family);
      setNationCode(state.registration.information.data.nationCode);
      setMobile(state.registration.information.data.mobile);
      setImage("https://static.mashinno.com/vendor/registration/" + state.registration.information.data.image[1000]);
      setHasImage(true);
    } catch (e) {

    }

  }, [])


  const confirm = () => {
    try {
      validateString(name, "نام", {min: 3, max: 30})
      validateString(family, "نام خانوادگی", {min: 3, max: 30})
      validateString(nationCode, "کد ملی", {min: 10, max: 10})
      validateString(mobile, "شماره موبایل", {min: 11, max: 11})
      if (image.length < 10) throw new Error("تصویر کارت ملی الزامی است")

      let toast = xToast.loading("در حال ذخیره اطلاعات")

      webRequest({
        url: "/registration/completeInformation",
        data: {
          type: "true",
          hasImage,
          name, family, nationCode, mobile, image
        },
        auth: state.auth
      }).then(response => {
        try {
          validateResponse(response)
          toast.updateAndDismiss("success", response.payload.message, 3000)


          dispatch({
            type: "update-registration",
            payload: {
              ...state.registration,
              information: response.payload.data,
              page: "address",
              level: 3
            }
          })

        } catch (e: any) {
          toast.updateAndDismiss("error", e.message, 3000)
        }
      })


    } catch (e: any) {
      xToast.error(e.message);
    }
  }

  const imageSelected = (event: any) => {
    let fileReader = new FileReader()
    fileReader.onloadend = () => {
      let image: any = fileReader.result
      setImage(image)
      setHasImage(false)
    }
    fileReader.readAsDataURL(event.target.files[0]);
  }

  let _nationCode = getOnlyNumbers(nationCode)
  let _mobile = getOnlyNumbers(mobile)


  const back = () => {
    dispatch({
      type: "update-registration",
      payload: {
        ...state.registration,
        page: "information",
        level: 2
      }
    })
  }


  let isValid = () => {
    try {
      validateString(name, "نام", {min: 3, max: 30})
      validateString(family, "نام خانوادگی", {min: 3, max: 30})
      validateString(nationCode, "کد ملی", {min: 10, max: 10})
      validateString(mobile, "شماره موبایل", {min: 11, max: 11})
      if (image.length < 10) throw new Error("تصویر کارت ملی الزامی است")
      return true
    } catch (e: any) {
      return false
    }
  }

  let canContinue = isValid()


  let footer = <>
    <div style={{cursor: "pointer", borderBottom: "1px dashed "}} className={"text-primary"} onClick={back}>
      بازگشت
    </div>
    <div style={{width: "32px"}}></div>
    <div onClick={confirm}>
      <span className={`btn btn-confirm-footer ${canContinue ? "btn-primary" : "btn-dark"}`}>ثبت و ادامه</span>
    </div>
  </>


  return (
    <RegisterContent title={"اطلاعات شخص حقیقی"} footer={footer}>

      <div className={"row row-small-padding mt-3"}>
        <Input parentClass={"col-6"} label={"نام"} center={true} value={name} setValue={setName}/>
        <Input parentClass={"col-6"} label={"نام خانوادگی"} center={true} value={family} setValue={setFamily}/>
      </div>

      <div className={"row row-small-padding mt-3"}>
        <Input parentClass={"col-6"} label={"کد ملی"} value={_nationCode} center={true} setValue={setNationCode}/>
        <Input parentClass={"col-6"} label={"شماره تماس"} value={_mobile} center={true} setValue={setMobile}/>
      </div>

      <input type="file" ref={fileRef} style={{display: "none"}} accept="image/*" onChange={imageSelected}/>

      <div style={{width: '300px', height: "200px", border: "1px dashed #aaa", borderRadius: "12px", margin: "24px auto", textAlign: "center", cursor: "pointer", position: "relative"}} onClick={() => fileRef.current.click()}>
        <div style={{position:"relative",top:"60px"}}>
          <span style={{borderBottom: "1px dashed #aaa",color:"#222"}}>تصویر کارت ملی</span>
          <img ref={imageRef} src={image} alt="" width="280px" height="180px" style={{position: "absolute", left: "0", right: "0", top: "40px", bottom: "0", margin: "auto auto", borderRadius: "12px", border: "1px dashed #fff"}}/>
        </div>

        <div style={{position:"relative",top:"80px",fontSize:"13px",color:"#666"}}>
          ( تصویر کارت ملی باید از جنس  png ، jpg یا bmp باشد )
        </div>

      </div>


    </RegisterContent>
  );
};

export default CompleteTrueInformation;