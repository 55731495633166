import React, {useEffect, useState} from 'react';

import "./app-chat.css"
import useScript from "../../../commons/useScript";
import Chats from "./Chats";
import Users from "./Users";
import useAppContext from "../../../Infrastructure/state/useAppContext";
import {webRequest} from "../../../Infrastructure/services/apiService";
import ChatInput from "./ChatInput";
import ChatHeader from "./ChatHeader";
import AddTicketDialog from "./AddTicketDialog";

const TicketsPage = () => {

  useScript("/assets/js/app-chat.js")


  let {state} = useAppContext()
  let [users, setUsers] = useState<undefined | []>(undefined)
  let [chats, setChats] = useState<undefined | any[]>(undefined)
  let [ticket, setTicket] = useState<undefined | any>(undefined)
  let [chatLoading, setChatLoading] = useState<boolean>(false)

  let [addDialog, setAddDialog] = useState<boolean>(false)


  useEffect(() => {
    if (!state.auth) return;
    getData()
  }, [state.auth]);

  let getData = () => {
    webRequest({
      url: "/tickets",
      auth: state.auth
    }).then(res => {
      setUsers(res.payload)
    })
  }

  const userClicked = (ticket: any) => {
    setTicket(ticket)
    setChatLoading(true)
    setChats([])
    webRequest({
      url: '/tickets/lines',
      auth: state.auth,
      data: {
        id: ticket,
      }
    }).then(response => {
      setChatLoading(false)
      setChats(response.payload)
    })

  }


  let startChat = (records: [], user: any) => {
    setUsers(records)
    userClicked(user)
  }

  let added = (ticketLine:any) => {
    if (!ticket) return;

    if (ticketLine.ticketId == ticket.id) {
      let records = chats ? chats : [];

      setChats([
        ...records,
        ticketLine
      ]);
    }
  }

  const getImage = (): string => {
    try {
      if (typeof state.auth?.user?.avatar === "string") {
        return "https://mashinno.com/x-api/files/uploads/avatars/" + state.auth?.user?.avatar
      }
      return "/assets/img/user/avatar.png";
    } catch (e: any) {
      return "/assets/img/user/avatar.png";
    }
  }



  return (
    <div className="">
      <AddTicketDialog show={addDialog} setShow={setAddDialog} confirmAndStart={startChat}/>
      <div className="app-chat card overflow-hidden">
        <div className="row g-0">

          <div className="col app-chat-contacts app-sidebar flex-grow-0 overflow-hidden border-end bg-white" id="app-chat-contacts">
            <div className="sidebar-header">
              <div className="d-flex align-items-center me-3 me-lg-0">
                <div className="flex-shrink-0 avatar avatar-online me-3" data-bs-toggle="sidebar" data-overlay="app-overlay-ex" data-target="#app-chat-sidebar-left">
                  <img alt="آواتار" className="user-avatar rounded-circle cursor-pointer" src={getImage()}/>
                </div>
                <div className="flex-grow-1 input-group input-group-merge rounded-pill">
              <span className="input-group-text" id="basic-addon-search31">
                <i className="ti ti-search h-mirror"/>
              </span>
                  <input aria-describedby="basic-addon-search31" aria-label="جستجو..." className="form-control chat-search-input" placeholder="جستجو..." type="text"/>
                </div>
              </div>
              <i className="ti ti-x cursor-pointer d-lg-none d-block position-absolute mt-2 me-1 top-0 end-0" data-bs-toggle="sidebar" data-overlay data-target="#app-chat-contacts"/>
            </div>
            <hr className="container-m-nx m-0"/>
            <Users users={users} clicked={userClicked} addNew={() => setAddDialog(true)}/>
          </div>


          {<div className="col app-chat-history bg-body">
            <div className="chat-history-wrapper">
              <ChatHeader ticket={ticket}/>
              <Chats ticket={ticket} records={chats} loading={chatLoading}/>
              <ChatInput ticket={ticket} addedTicket={added}/>
            </div>
          </div>}
          <div className="app-overlay"/>
        </div>
      </div>
    </div>

  );
};

export default TicketsPage;