function reloadState(data: any, defaultData: any) {
  let newState
  if (data) {
    let parsedData
    try {
      parsedData = JSON.parse(data);
    } catch (e) {
      parsedData = defaultData
    }

    let initState = {
      ...defaultData,
      ...parsedData
    }
    initState.isLoaded = true;
    newState = initState
  } else {
    newState = defaultData
    newState.isLoaded = true;
  }
  return newState
}

const appReducer = (state: any, action: any) => {
  let newState = state


  switch (action.type) {
    case "DISPATCH_STATE":

      newState = reloadState(window.localStorage.getItem("state"), state)
      break;

    case "RELOAD_STATE":
      newState = reloadState(action.payload, state)
      break;

    case "on-login":
      newState = {
        ...state,
        isVendor: action.payload.isVendor,
        auth: action.payload.auth,
        vendor: action.payload.vendor,
        completedVendor:action.payload.completedVendor,
        confirmedVendor:action.payload.confirmedVendor,
        registration: {
          ...action.payload.registration,
        },
      }
      break;

    case "update-registration":
      newState = {...state, registration: action.payload}
      break;

    case "SAVE_STATES":
      newState = {...state, states: action.payload}
      break;


  }

  if (newState.isLoaded) {
    window.localStorage.setItem("state", JSON.stringify(newState))
  }

  return newState
}

export default appReducer
