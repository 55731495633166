import React from 'react';
import Skeleton from "react-loading-skeleton";


const SkeletonRow = () => {
  return (
    <div style={{borderTop: "1px solid #eee", paddingBottom: "4px", paddingTop: "4px", display: "flex",}}>
      <Skeleton width="120px" height="100px"/>
      <div style={{marginRight: "16px", width: "100%"}}>
        <div>
          <Skeleton width="calc(96% )" height="10px"/>
          <Skeleton width="100px" height="10px"/>
        </div>
        <div style={{display: "flex",marginTop:"20px"}}>
          <Skeleton width="80px" height="10px"/>
          &nbsp;
          &nbsp;
          <Skeleton width="80px" height="10px"/>
        </div>
        <div style={{display: "flex",marginTop:"0"}}>
          <Skeleton width="60px" height="10px"/>
          &nbsp;
          &nbsp;
          <Skeleton width="100px" height="10px"/>
        </div>

      </div>


    </div>
  );
};


const MobileSkeleton = () => {
  return (
    <div>
      <SkeletonRow/>
      <SkeletonRow/>
      <SkeletonRow/>
      <SkeletonRow/>
      <SkeletonRow/>
      <SkeletonRow/>
      <SkeletonRow/>
    </div>
  );
};

export default MobileSkeleton;