import React, {useEffect, useState} from 'react';
import useAppContext from "../../../../Infrastructure/state/useAppContext";
import {webRequest} from "../../../../Infrastructure/services/apiService";
import RegisterContent from "../Content/RegisterContent";
import xToast from "../../../../commons/xToast";
import HtmlText from "../../../Ui/HtmlText";

import "./contract.css"
import Skeleton from "react-loading-skeleton";
import {dismissConfirmDialog, showConfirmDialog} from "../../../../hooks/confirmDialog";
import Checkbox from "../../../Ui/Checkbox";

const ContractPage = () => {
  let {state, dispatch} = useAppContext();
  let [loaded, setLoaded] = useState(false);
  let [text, setText] = useState<string>("");
  let [vendorData, setVendorData] = useState<any>(undefined);
  let [categories, setCategories] = useState<[]>([]);

  let [isChecked, setIsChecked] = useState<boolean>(false);


  useEffect(() => {

    webRequest({
      url: "/registration/getContract",
      auth: state.auth
    }).then(response => {
      setText(response.payload.text)
      setVendorData(response.payload.data)
      setCategories(response.payload.categories)
      setLoaded(true)
    })
  }, [])


  const confirm = () => {
    try {

      if (!isChecked) {
        xToast.error("پذیرش قرارداد الزامی است")
        return;
      }

      let toast = xToast.loading("در حال بررسی اطلاعات")

      webRequest({
        url: "/registration/confirmContract",
        auth: state.auth
      }).then(response => {
        toast.dismiss()
        dispatch({
          type: "on-login",
          payload: {
            ...state,
            ...response.payload
          }
        })
      })

    } catch (e: any) {

    }
  }


  const back = () => {
    showConfirmDialog({
      title: "بازگشت؟",
      height: 160,
      description: "آیا می خواهید به مرحله قبلی برگردید؟",
      onConfirmButtonClicked: () => {
        dispatch({
          type: "update-registration",
          payload: {
            ...state.registration,
            page: "documents",
            level: 4
          }
        })

        dismissConfirmDialog()

      }
    })
  }


  let footer = <>
    <div style={{cursor: "pointer", borderBottom: "1px dashed "}} className={"text-primary"} onClick={back}>
      بازگشت
    </div>
    <div style={{width: "32px"}}></div>
    <div onClick={confirm}>
      <span className={`btn btn-confirm-footer ${isChecked ? "btn-primary" : "btn-dark"}`}>ثبت و ادامه</span>
    </div>
  </>

  return (
    <RegisterContent title={"پذیرش قرارداد"} footer={footer}>

      {loaded && <>
        <HtmlText text={text}/>

        <div className={"tac mt-1 mb-4"} style={{color: "#222", fontWeight: "bold"}}>
          کمسیون بر اساس دسته بندی
        </div>

        <table className={"table table-bordered tac"}>
          <thead>
          <tr>
            <th>ردیف</th>
            <th>دسته بندی</th>
            <th>درصد</th>
            <th>توضیحات</th>
          </tr>
          </thead>

          <tbody>
          {categories.map((row: any, index: number) => {
            return <tr key={index}>
              <td>{index + 1}</td>
              <td>{row.label}</td>
              <td>{row.commission}</td>
              <td>{row.description && row.description.length > 0 ? row.description : row.label}</td>
            </tr>
          })}
          </tbody>

        </table>

        <hr/>

        <Checkbox label={"متن بالا را خواندم و می پذیرم"} parentClass={"mt-2 mb-3"} checked={isChecked} setChecked={setIsChecked}/>


      </>}

      {!loaded && <>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"80%"} height={"12px"}/>
        <br/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"20%"} height={"12px"}/>
        <br/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"60%"} height={"12px"}/>
        <br/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"20%"} height={"12px"}/>
        <br/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"60%"} height={"12px"}/>
        <br/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"100%"} height={"12px"}/>
        <Skeleton width={"60%"} height={"12px"}/>
        <br/>


      </>}

    </RegisterContent>
  );
};

export default ContractPage;