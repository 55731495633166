import React, {useEffect} from "react";
import StateProvider from "../../Infrastructure/state/StateProvider";
import Navigation from "./Navigation";
import Header from "./Header";
import Footer from "./Footer";
import AuthenticatedComponent from "./AuthenticatedComponent";


const MainLayout = () => {

  useEffect(() => {
    const customEvent = new CustomEvent('customEventType');
    document.dispatchEvent(customEvent);
  }, []);


  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Navigation/>
          <div className="layout-page">
            <Header/>
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <main className={""}>
                  <StateProvider>
                    <AuthenticatedComponent/>
                  </StateProvider>
                </main>
              </div>
              <Footer/>
              <div className="content-backdrop fade"/>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"/>
        {/*<div className="drag-target"/>*/}
      </div>

    </>
  );
};

export default MainLayout;