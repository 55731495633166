import React from 'react';
import Skeleton from "react-loading-skeleton";

const MessageSkeleton = () => {

  const randomWidth = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }


  return (
    <li className="chat-message">
      <div className="d-flex overflow-hidden">
        <div className="user-avatar flex-shrink-0 me-3">
          <div className="avatar avatar-sm">
            {/*<img alt="آواتار" className="rounded-circle" src="../../assets/img/avatars/2.png"/>*/}
          </div>
        </div>
        <div className="chat-message-wrapper flex-grow-1 ">
          <Skeleton width={randomWidth(200,500)} height={42}/>
          <div className="text-muted mt-1">
            <small>
              <Skeleton width={70}/>
            </small>
          </div>
        </div>
      </div>
    </li>
  );
};

export default MessageSkeleton;