import React, {useEffect, useState} from 'react';
import useAppContext from "../../../Infrastructure/state/useAppContext";
import {Paginator} from "../../../Infrastructure/services/Types/Paginator";
import {IOrder} from "../../../Infrastructure/services/Types/IOrder";
import {webRequest} from "../../../Infrastructure/services/apiService";
import OrderDetailsDialog from "../OrdersPage/OrderDetailsDialog";
import ClickablePagination from "../../Ui/ClickablePagination";
import DesktopSkeleton from "../ProductPage/DesktopSkeleton";
import MobileSkeleton from "../ProductPage/MobileSkeleton";
import {numberFormat} from "../../../commons/numberHelper";

const CallForPricePage = () => {

  let {state, dispatch} = useAppContext()
  let [orders, setOrders] = useState<Paginator<IOrder> | undefined>(undefined)

  let [keyword, setKeyword] = useState<string>("")
  let [page, setPage] = useState<number>(1)

  useEffect(() => {

    webRequest({
      url: "/orders/all",
      data: {},
      auth: state.auth
    }).then(response => {
      setOrders(response.payload.orders)
    })


  }, []);


  const showOrderDetails = (row:any) => {
  }


  let width = window.innerWidth;


  const onKeyword = (keyword: string) => {
    setKeyword(keyword)
    setPage(1)
    // let url = buildProductPageUri(brand, car, category, 1, keyword)
    // navigate(url)
  }

  const pageClicked = (page: any) => {
    setPage(page)
  }


  return (
    <div className={"card p-2"}>
      <div className={"filter-container"}>
        <div className={"search-input"}>
          <label htmlFor="" style={{color: "#222"}}>جست و جو</label>
          <input value={keyword} onChange={e => onKeyword(e.target.value)} type="text" placeholder={"جست و جو در محصولات"} style={{width: "calc(100%)", borderRadius: "8px", outline: "0", border: "1px solid #ccc", paddingRight: "8px", height: "48px"}}/>
          {/*<button className="btn btn-secondary add-new btn-primary  ms-sm-0 waves-effect waves-light">*/}
          {/*  <i className="ti ti-plus me-0 me-sm-1 ti-xs"/>*/}
          {/*  <span className="d-none d-sm-inline-block" onClick={filterDone}>فیلتر کردن</span>*/}
          {/*</button>*/}
        </div>
        <div className="filter-select">
          <div>
            <span style={{color: "#222"}}>برند</span>
            {/*<XSelect placeholder={"جست و جو"} records={props.brands} optionSelected={brandSelected} defaultOption={brandSelectValue}/>*/}
          </div>

          <div>
            <span style={{color: "#222"}}>خودرو</span>
            {/*<XSelect placeholder={"جست و جو"} records={cars} optionSelected={carSelected} defaultOption={carSelectValue}/>*/}
          </div>

          <div>
            <span style={{color: "#222"}}>دسته بندی</span>
            {/*<XSelect placeholder={"جست و جو"} records={props.categories} optionSelected={categorySelected} defaultOption={categorySelectValue}/>*/}
          </div>
        </div>
      </div>
      {width > 991 && orders && <ClickablePagination lastPage={orders?.lastPage} currentPage={page} total={orders?.total} clicked={pageClicked}/>}

      {!orders && <>{width > 991 ? <DesktopSkeleton/> : null}</>}
      {!orders && <>{width <= 991 ? <MobileSkeleton/> : null}</>}


      {orders && <table className={"table table-bordered tac"}>
        <thead>
        <tr>
          <td>شماره سفارش</td>
          <td>مبلغ فاکتور</td>
          <td>وضعیت</td>
          <td>آدرس</td>
          <td>آخرین پیام</td>
          <td>تاریخ ثبت</td>
          <td>عملیات</td>
        </tr>
        </thead>
        {orders && <>
          <tbody>
          {orders.records.map((row, index) => {
            return <tr key={index}>
              <td>{row.id}</td>
              <td>{numberFormat(row.amount)}</td>
              <td>{row.statusText}</td>
              <td>{row.address}</td>
              <td>{row.latest_message}</td>
              <td>{row.dateText}</td>
              <td>
                <i className={"ti ti-eye cp"} onClick={() => showOrderDetails(row)}></i>
              </td>
            </tr>
          })}
          </tbody>
        </>}
      </table>
      }

    </div>
  );
};

export default CallForPricePage;