import React, {useEffect, useState} from 'react';
import {webRequest} from "../../../Infrastructure/services/apiService";
import useAppContext from "../../../Infrastructure/state/useAppContext";
import {Paginator} from "../../../Infrastructure/services/Types/Paginator";
import {IOrder} from "../../../Infrastructure/services/Types/IOrder";
import ClickablePagination from "../../Ui/ClickablePagination";
import {numberFormat} from "../../../commons/numberHelper";
import OrderDetailsDialog from "./OrderDetailsDialog";
import OrdersSkeleton from "./OrdersSkeleton";


const OrdersPage = () => {

  let {state, dispatch} = useAppContext()
  let [orders, setOrders] = useState<Paginator<IOrder> | undefined>(undefined)

  let [keyword, setKeyword] = useState<string>("")
  let [page, setPage] = useState<number>(1)

  let [order, setOrder] = useState<any>(undefined)

  useEffect(() => {
    getOrders(page, "")
  }, []);


  let getOrders = (page: number, keyword: string) => {
    setOrders(undefined)
    webRequest({
      url: "/orders/all",
      data: {
        keyword, page
      },
      auth: state.auth
    }).then(response => {
      setOrders(response.payload.orders)
    })

  }


  const showOrderDetails = (row: any) => {
    setOrder(row)
  }


  let width = window.innerWidth;


  const onKeyword = (keyword: string) => {
    setKeyword(keyword)
    setPage(1)
    getOrders(1, keyword)
    // let url = buildProductPageUri(brand, car, category, 1, keyword)
    // navigate(url)
  }

  const pageClicked = (page: any) => {
    setPage(page)
    getOrders(page,keyword)
  }


  return (
    <div className={"card p-2"}>
      <OrderDetailsDialog dismiss={() => setOrder(undefined)} order={order} show={order !== undefined}/>

      <div className={""} style={{maxWidth: "600px", marginBottom: "16px"}}>
        <div className={"search-input"}>
          <label htmlFor="" style={{color: "#222", lineHeight: "28px"}}>جست و جو</label>
          <input value={keyword} onChange={e => onKeyword(e.target.value)} type="text" placeholder={"جست و جو : شماره سفارش یا شماره موبایل"} style={{width: "calc(100%)", borderRadius: "8px", outline: "0", border: "1px solid #ccc", paddingRight: "8px", height: "48px"}}/>
        </div>
      </div>

      <ClickablePagination lastPage={orders?.lastPage} currentPage={page} total={orders?.total} clicked={pageClicked}/>

      {!orders && <OrdersSkeleton/>}


      {orders  &&  <table className={"table table-bordered tac"}>
        <thead>
        <tr>
          <td>شماره سفارش</td>
          <td>مبلغ فاکتور</td>
          <td>وضعیت</td>
          <td>آدرس</td>
          <td>آخرین پیام</td>
          <td>تاریخ ثبت</td>
          <td>عملیات</td>
        </tr>
        </thead>
        {orders && <>
          <tbody>
          {orders.records.map((row, index) => {
            return <tr key={index}>
              <td>{row.id}</td>
              <td>{numberFormat(row.amount)}</td>
              <td>{row.statusText}</td>
              <td>{row.address}</td>
              <td>{row.latest_message}</td>
              <td>{row.dateText}</td>
              <td>
                <i className={"ti ti-eye cp"} onClick={() => showOrderDetails(row)}></i>
              </td>
            </tr>
          })}
          </tbody>
        </>}

        {orders && orders.records.length === 0 && <>
          <tbody>
          <tr>
            <td colSpan={7} style={{textAlign: "center"}}>
              موردی یافت نشد
            </td>
          </tr>
          </tbody>
        </>}

      </table>
      }
      <ClickablePagination lastPage={orders?.lastPage} currentPage={page} total={orders?.total} clicked={pageClicked}/>



    </div>
  );
};

export default OrdersPage;