import React from 'react';
import {ProductLite} from "../../../Infrastructure/services/Types/Product";
import MobileMenu from "./MobileMenu";

interface Props {
  records: ProductLite[],
  // onDelete: Function,
  // onAdd: Function,
  onRefresh: Function,
  onAddPrice: Function,
}


const MobileMode = (props: Props) => {
  return (
    <div className="">


      {props.records.map((row, index) => {
        return <MobileMenu
          row={row}
          // onAdd={(row: ProductLite) => props.onAdd(row)}
          // onDelete={(row: ProductLite) => props.onDelete(row)}
          onAddPrice={(row: ProductLite) => props.onAddPrice(row)}
          onRefresh={() => props.onRefresh()}

          key={index}
        />
      })}
    </div>
  );
};

export default MobileMode;