import Select, {components} from 'react-select';
import {ISelectType} from "../../../Infrastructure/types/ISelectType";
import React from "react";
import "./select.css"




const NoOptionsMessage = (props: any) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">موردی یافت نشد</span>
    </components.NoOptionsMessage>
  );
};


const XSelect = (
  {
    records,
    placeholder,
    defaultOption,
    optionSelected,
    label,
    parentClass,
    isMultiple
  }:
    {
      label?: string,
      placeholder?: string,
      records: ISelectType[],
      defaultOption?: ISelectType|null|ISelectType[],
      optionSelected: Function,
      parentClass?: string,
      isMultiple?:boolean
    }) => {

  const changed = (data: any) => optionSelected(data)


  let value:any = defaultOption;


  // if (value) {
  //   let isValid = false;
  //   for (let i = 0; i < records.length; i++) {
  //     if (records[i].value === value.value) {
  //       isValid = true;
  //     }
  //   }
  //   if (!isValid) {
  //     value = null
  //   }
  // }

  return (
    <div className={parentClass ?? ""}>
      {label && <label htmlFor="">{label}</label>}
      <Select
        defaultValue={value}
        placeholder={<div style={{fontSize: "12px"}}>{placeholder ?? "جست و جو"}</div>}
        value={value}
        isMulti={isMultiple}
        maxMenuHeight={200}
        components={{NoOptionsMessage}}
        onChange={changed}
        options={records}
      />

    </div>
  );
};

export default XSelect;