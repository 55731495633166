import React from 'react';
import RegisterAside from "./Aside/RegisterAside";
import Information from "./Information/Information";
import LoginPage from "./Login/LoginPage";
import useAppContext from "../../../Infrastructure/state/useAppContext";
import CompleteTrueInformation from "./Information/CompleteInformation/CompleteTrueInformation";
import CompleteLegalInformation from "./Information/CompleteInformation/CompleteLegalInformation";
import AddressPage from "./Address/AddressPage";
import "./authPage.css"
import JobTitleAndCategory from "./JobTitle/JobTitleAndCateglry";
import ContractPage from "./Contract/ContractPage";
import DonePage from "./DonePage/DonePage";

const AuthPage = () => {

  let {state} = useAppContext()

  return (
    <>






      {state && state.registration ? <>
        {state.registration.page === "login" && <>
          <LoginPage/>
        </>}

        {state.registration.page === "done" && <DonePage/>}

        {((state.registration.page !== "login" && state.registration.page !== "done" ) && state.auth) && <div className={"container x-container"} style={{position: "relative"}}>
          <RegisterAside/>
          {state.auth && <div className={"page-content"}>

            {state.registration.page === "information" && <Information/>}
            {state.registration.page === "true-information" && <CompleteTrueInformation/>}
            {state.registration.page === "legal-information" && <CompleteLegalInformation/>}
            {state.registration.page === "address" && <AddressPage/>}
            {state.registration.page === "documents" && <JobTitleAndCategory/>}
            {state.registration.page === "contract" && <ContractPage/>}

          </div>}


        </div>}
      </> : <LoginPage/>}

    </>
  );
};

export default AuthPage;