import React, {useEffect, useState} from 'react';
import RegisterContent from "../Content/RegisterContent";
import "./information.css"
import CheckboxBox from "../../../Ui/CheckboxBox";
import {dismissConfirmDialog, showConfirmDialog} from "../../../../hooks/confirmDialog";
import xToast from "../../../../commons/xToast";
import useAppContext from "../../../../Infrastructure/state/useAppContext";
import {toast} from "react-toastify";

const Information = () => {

  let {state, dispatch} = useAppContext()

  let [type, setType] = useState<string | null>(null);


  useEffect(() => {
    setTimeout(function (){
      toast.dismiss()
    },3000)

    try {
      setType(state.registration.information.type)
    } catch (e) {
    }
  }, [])


  const confirm = () => {
    if (type === null) {
      xToast.defaultAndClose("لطفا یکی از موارد را انتخاب کنید")
      return;
    }

    dispatch({
      type: "update-registration",
      payload: {
        ...state.registration,
        information: {
          ...state.registration.information,
          type,
        },
        page: type === "true" ? "true-information" : "legal-information",
        level: 2
      }
    })

  }

  const back = () => {

    showConfirmDialog({
      title: "از مراحل ثبت نام خارج می‌شوید؟",
      height: 180,
      description: " اطلاعات شما ذخیره خواهد شد و می‌توانید هر زمان ثبت نام خود را ادامه دهید. ",
      onConfirmButtonClicked: () => {
        dispatch({
          type: "on-login",
          payload: {
            auth: null,
            isVendor: false,
            vendor: null,
            isApproved:false,
            registration: {
              type: null,
              page: "login"
            }
          }
        })
        dismissConfirmDialog()
      }
    })

  }

  let footer = <>
    <div style={{cursor: "pointer", borderBottom: "1px dashed "}} className={"text-primary"} onClick={back}>
      بازگشت
    </div>
    <div style={{width: "32px"}}></div>
    <div onClick={confirm}>
      <span className={`btn btn-confirm-footer ${type ? "btn-primary" : "btn-dark"}`}>ثبت و ادامه</span>
    </div>
  </>

  return (
    <RegisterContent title={"اطلاعات حقیقی یا حقوقی"} footer={footer}>

      <div className="options-container">
        <div onClick={() => setType("true")}>
          <CheckboxBox title={"شخص حقیقی"} description={"شخص حقیقی، فردی است که دارای مشخصاتی مثل نام، نام خانوادگی، کد ملی و... می‌باشد و شرکت یا الزاما کد اقتصادی ندارد"} active={type === "true"}/>
        </div>

        <div className="mt-3" onClick={() => setType("legal")}>
          <CheckboxBox title={"شخص حقوقی"} description={"شخص حقوقی یک موسسه یا شرکت است که به صورت قانونی ثبت شده و مشخصاتی مانند تاشماره ثبت، کد شناسایی، و کد اقتصادی دارد."} active={type === "legal"}/>
        </div>
      </div>

    </RegisterContent>
  );
};

export default Information;