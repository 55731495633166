import React from 'react';
import useAppContext from "../../../Infrastructure/state/useAppContext";
import {Link, useNavigate} from "react-router-dom";

const HeaderProfile = () => {
  let {state, dispatch} = useAppContext()
  let navigate = useNavigate()

  if (!state) return  <></>
  if (!state.auth) return  <></>
  if (!state.auth.user) return  <></>




  let logout = () => {
    dispatch({
      type: "on-login",
      payload: {
        auth: null,
        isVendor: false,
        isApproved: false,
        vendor: null,
        registration: {
          type: null,
          page: "login"
        }
      }
    })

    navigate("/auth")
  }
  if (!state) return  <></>
  if (!state.auth) return  <></>

  const getImage = (): string => {
    try {
      if (typeof state.auth?.user?.avatar === "string") {
        return "https://mashinno.com/x-api/files/uploads/avatars/" + state.auth?.user?.avatar
      }
      return "/assets/img/user/avatar.png";
    } catch (e: any) {
      return "/assets/img/user/avatar.png";
    }
  }


  return (
    <ul className="navbar-nav flex-row align-items-center ms-auto">
      {/* User */}
      <li className="nav-item navbar-dropdown dropdown-user dropdown">
        <a
          className="nav-link dropdown-toggle hide-arrow"
          data-bs-toggle="dropdown"
          // href="javascript:void(0);"
        >
          <div className="avatar avatar-online">
            <img
              alt=""
              className="h-auto rounded-circle"
              style={{aspectRatio:"1/1",width:"38px",height:"38px"}}
              src={getImage()}
            />
          </div>
        </a>
        <ul className="dropdown-menu dropdown-menu-end">
          <li>
            <Link className="dropdown-item" to="/edit-profile">
              <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar avatar-online">
                    <img
                      alt=""
                      style={{aspectRatio:"1/1",width:"38px",height:"38px"}}
                      className="h-auto rounded-circle"
                      src={getImage()}
                    />
                  </div>
                </div>
                <div className="flex-grow-1">
                        <span className="fw-semibold d-block mb-1">
                          {state.auth!.user!.name}
                        </span>
                  <small className="text-muted">فروشنده</small>
                </div>
              </div>
            </Link>
          </li>
          <li>
            <div className="dropdown-divider"/>
          </li>
          <li>
            <Link className="dropdown-item" to="/edit-profile">
              <i className="ti ti-user-edit me-2 ti-sm"/>
              <span className="align-middle">ویرایش اطلاعات کاربری</span>
            </Link>
          </li>
          <li>
            <span className="dropdown-item" style={{cursor: "pointer"}} onClick={logout}>
              <i className="ti ti-logout me-2 ti-sm"/>
              <span className="align-middle">خروج از حساب</span>
            </span>
          </li>
        </ul>
      </li>
      {/*/ User */}
    </ul>
  );
};

export default HeaderProfile;