import React from 'react';
import Skeleton from "react-loading-skeleton";
import PaginationSkeleton from "./PaginationSkeleton";


const XTr = () => {
  return (
    <tr>
      <td style={{textAlign: "right"}}>
        <Skeleton width={120} height={80}/>
      </td>
      <td style={{textAlign: "right"}}>
        <Skeleton width={250} height={20}/>
        <Skeleton width={150} height={20}/>
      </td>
      <td style={{textAlign: "right"}}><Skeleton width={100} height={20}/></td>
      <td style={{textAlign: "right"}}><Skeleton width={100} height={20}/></td>

      <td>
        <div style={{display: "flex"}}>
          <Skeleton width={32} height={32}/>
          &nbsp;
          &nbsp;
          <Skeleton width={32} height={32}/>
        </div>
      </td>
    </tr>
  );
};


const DesktopSkeleton = () => {
  return <>
    <div style={{marginTop:"20px"}} className="table-responsive">
      <table className="datatables-products table no-footer dtr-column table">
        <thead className="border-top">
        <tr style={{textAlign: "right"}}>
          {/*<th className="" style={{width: "24.8333px"}} data-col={1} aria-label="">
              <input type="checkbox" className="form-check-input"/>
            </th>*/}
          <th>
            <Skeleton width={120} height={20}/>
          </th>
          <th><Skeleton width={80} height={20}/></th>
          <th><Skeleton width={100} height={20}/></th>
          <th><Skeleton width={100} height={20}/></th>
          <th><Skeleton width={80} height={20}/></th>
        </tr>
        </thead>
        <tbody>
        <XTr/>
        <XTr/>
        <XTr/>
        <XTr/>
        <XTr/>
        <XTr/>
        <XTr/>
        <XTr/>
        </tbody>
      </table>


      <div className="row mx-2">

        {/*  <div className="col-sm-12 col-md-6">*/}
        {/*    <div*/}
        {/*      className="dataTables_paginate paging_simple_numbers"*/}
        {/*      id="DataTables_Table_0_paginate"*/}
        {/*    >*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>

    </div>
  </>
};

export default DesktopSkeleton;