import React from 'react';
import Skeleton from "react-loading-skeleton";

const EditSkeleton = () => {
  return (
    <div style={{overflow: 'hidden'}}>
      <Skeleton width={"100%"} height={"800px"} style={{overflow:"hidden"}}/>
    </div>
  );
};

export default EditSkeleton;