import {UserState} from "./Types/UserState";


export interface IState {
  auth: UserState|null,
  isLoaded: boolean
  isVendor: boolean,
  vendor: null | any,
  confirmedVendor:boolean,
  completedVendor:boolean,

  registration: {
    page: string,
    level: number,
    information: {
      type: string | null,
      data: any
    },
    address: any,
    jobTitle: any,

  }

}


let _state: IState = {
  auth: {},
  isLoaded: false,
  isVendor: false,
  confirmedVendor:false,
  completedVendor:false,
  vendor: null,
  registration: {
    information: {
      type: null,
      data: {}
    },
    address:null,
    jobTitle:null,
    page: "login",
    level: 0
  }
}


let storageData = window.localStorage.getItem("state");
if (storageData) storageData = JSON.parse(storageData);


let state = storageData ?? _state;


export default state


