import React, {useEffect, useRef, useState} from 'react';
import Input from "../../../Ui/Input/Input";
import {webRequest} from "../../../../Infrastructure/services/apiService";
import TextArea from "../../../Ui/TextArea/TextArea";
import useAppContext from "../../../../Infrastructure/state/useAppContext";
import {validateResponse} from "../../../../Infrastructure/services/validateResponse";
import xToast from "../../../../commons/xToast";

const AddProductPage = () => {

  let [name, setName] = useState("")
  let [brand, setBrand] = useState("")
  let [country, setCountry] = useState("")
  let [description, setDescription] = useState("")
  let fileRef: any = useRef()
  let imageRef: any = useRef()
  let buttonRef: any = useRef()
  let [image, setImage] = useState<any>("")
  let {state} = useAppContext()


  useEffect(() => {

    if (!state.auth) {
      return;
    }


    reloadData()
    buttonRef.current.addEventListener("click", () => fileRef.current.click())

    fileRef.current.addEventListener("change", function (event: any) {
      let fileReader = new FileReader()
      fileReader.onloadend = function () {
        imageRef.current.setAttribute("src", fileReader.result);
        setImage(fileReader.result)
      }
      fileReader.readAsDataURL(event.target.files[0]);
    })


  }, [state.auth]);


  const confirm = () => {
    let toast = xToast.loading("در حال ثبت درخواست لطفا صبر کنید")
    webRequest({
      url: "/products/create",
      data: {
        name, brand, country, description, image
      },
      auth: state.auth
    }).then(response => {

      try {
        validateResponse(response)
        toast.updateAndDismiss("success", "عملیات با موفقیت انجام شد", 3000)

        setName("")
        setBrand("")
        setCountry("")
        setImage("")
        setDescription("")

        reloadData()
      } catch (e: any) {
        toast.updateAndDismiss("error", e.message, 3000)
      }
    })
  }


  let [loaded, setLoaded] = useState(false)
  let [products, setProducts] = useState<any[]>([])

  const reloadData = () => {
    setLoaded(false)
    webRequest({
      url: "/products/getRequests",
      auth: state.auth,
    }).then(response => {
      setLoaded(true)
      try {
        validateResponse(response)
        setProducts(response.payload)
      } catch (e: any) {

      }
    })
  }


  return (
    <>
      <div className="card p-3">
        <div className={"mb-3"}>
          ساخت محصول جدید
        </div>

        <div className={"mt-3"}>
          <Input label={"نام محصول"} value={name} setValue={setName}/>
        </div>
        <div className={"mt-3"}>
          <Input label={"برند"} value={brand} setValue={setBrand}/>
        </div>

        <div className={"mt-3"}>
          <Input label={"کشور سازنده"} value={country} setValue={setCountry}/>
        </div>

        <div className={"mt-3"}>
          <TextArea label={"توضیحات محصول ( غیر الزامی )"} value={description} setValue={setDescription}/>
        </div>

        <div style={{marginTop: "178px !important"}}>
          <div className={"tac"} style={{marginTop: "16px"}}>
            <div>
              <img src="" alt="" width={180} ref={imageRef} style={{minHeight: 160, height: "auto !important", border: "1px dashed #ddd", borderRadius: "8px"}}/>
              <input type="file" style={{display: "none"}} ref={fileRef}/>
            </div>
            <div className={"btn btn-success mt-2"} ref={buttonRef}>انتخاب تصویر محصول</div>
          </div>
        </div>


        <hr/>


        <div style={{marginTop: "16px"}}>
          <span style={{width: "240px"}} className={"btn btn-primary"} onClick={confirm}>تایید و در خواست ثبت محصول</span>
        </div>

      </div>


      <div className={"card p-3 mt-4"}>
        <table className={"table table-bordered tac"}>
          <thead>
          <tr>
            <th>نام محصول</th>
            <th>برند</th>
            <th>کشور</th>
            <th>وضعیت</th>
            <th>تصویر</th>
            <th>تاریخ درخواست</th>
          </tr>
          </thead>
          <tbody>
          {products.length===0 && <tr>
            <td colSpan={6} style={{textAlign:"center"}}>
              درخواستی ثبت نکرده اید
            </td>
          </tr>}
          {products.map((row: any, index: number) => {


            let done
            let color="#ff4444"
            if (row.done === 1) {
              done = "انجام شده"
               color="#00acac"
            } else {
              done = "انجام نشده"
            }


            return <tr key={index}>
              <td>{row.title}</td>
              <td>{row.brand}</td>
              <td>{row.country}</td>
              <td style={{color}}>{done}</td>
              <td>{row.image ? <div>
                <img src={row.image} alt="" width={80}/>
              </div>:<div>-</div>}</td>
              <td>{row.date}</td>
            </tr>
          })}
          </tbody>
        </table>
      </div>

    </>

  );
};

export default AddProductPage;