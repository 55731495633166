import React, {useRef, useState} from 'react';
import xToast from "../../../commons/xToast";
import {webRequest} from "../../../Infrastructure/services/apiService";
import useAppContext from "../../../Infrastructure/state/useAppContext";

const ChatInput = ({ticket, addedTicket}: any) => {

  let [keyword, setKeyword] = useState("")
  let {state, dispatch} = useAppContext()

  const checkSend = (event: any) => {
    if (event.key.toLowerCase() == "enter") {
      event.preventDefault()
      event.stopPropagation()
      send()
    }
  }

  let send = () => {
    if (!ticket) return;


    if (!keyword) return;

    if (keyword.trim().length < 3) {
      xToast.defaultAndClose("متن پیام نباید کمتر از 3 کاراکتر باشد")
      return;
    }
    let _keyword = keyword.trim();
    setKeyword("")

    let toast = xToast.loading("در حال ارسال تیکت")

    webRequest({
      url: "/tickets/add-line",
      data: {
        ticket: ticket.id,
        keyword: _keyword,
      },
      auth: state.auth
    }).then(response => {
      addedTicket(response.payload);

      toast.dismiss()
    })


  }

  let formSubmit = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    send()
  }

  let fileRef: any = useRef()

  let imageSelected = (event: any) => {
    
  }


  return (
    <>
      {ticket && <div className="chat-history-footer shadow-sm bg-white">
        <form className="form-send-message d-flex justify-content-between align-items-center" onSubmit={formSubmit}>
          <input className="form-control message-input border-0 me-3 shadow-none" value={keyword} placeholder="پیام خود را اینجا تایپ کنید" onChange={e => setKeyword(e.target.value)} onKeyUp={checkSend}/>
          <div className="message-actions d-flex align-items-center">
            {/*<i className="speech-to-text ti ti-microphone ti-sm cursor-pointer"/>*/}
            <label className="form-label mb-0" htmlFor="attach-doc">
              <i className="ti ti-photo ti-sm cursor-pointer mx-3" onClick={() => fileRef.current.click()}/>
              <input hidden id="attach-doc" type="file" ref={fileRef}/>
              <input type="file" style={{display: "none"}} onChange={imageSelected}/>
            </label>

            <button className="btn btn-primary d-flex send-msg-btn">
              <span className="align-middle d-md-inline-block d-none" onClick={send}>ارسال</span>
              <i className="ti ti-send ms-md-1 ms-0 ic-mirror"/>
            </button>
          </div>
        </form>
      </div>}
    </>
  );
};

export default ChatInput;