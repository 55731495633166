import React from 'react';

interface Props{
  title:string
  subtitle?:string,
  active:boolean
}

const AsideRow = (props:Props) => {
  return (
    <li className={""}>
      <div className={`radio  ${props.active?"active":""}`}>

        <div className={`radio-box ${props.active?"active":""}`}>
          <div className={`radio-checked ${props.active?"active":""}`}></div>
        </div>
      </div>

      <div className={"title-container"}>
        <div className={`title ${props.active?"active":""}`}>
          {props.title}
        </div>
        {props.subtitle && <div className={`subtitle  ${props.active?"active":""}`}>
          {props.subtitle}
        </div>}
      </div>

    </li>
  );
};

export default AsideRow;