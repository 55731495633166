import React, {useEffect} from 'react';

import "./aside.css"
import AsideRow from "./AsideRow";
import useAppContext from "../../../../Infrastructure/state/useAppContext";
import {toast} from "react-toastify";

const RegisterAside = () => {
  let {state} = useAppContext()

  useEffect(() => {
    setTimeout(() => toast.dismiss(), 3000)
  }, [])

  return (
    <div className={"aside"}>
      <img src="https://mashinno.com/uploads/settings/settings-13-color.png?c=0" width={180} alt="" style={{margin: "auto", display: "block"}}/>

      <nav className={"aside-nav"}>
        <ul>
          <AsideRow title={"ثبت نام فروشنده"} active={state.registration.level >= 0}/>
          <AsideRow title={"اطلاعات حقیقی یا حقوقی"} active={state.registration.level >= 2}/>
          <AsideRow title={"آدرس و کد پستی"} active={state.registration.level >= 3} subtitle={"آدرس پستی فروشگاه، کد پستی، شماره تماس ثابت، ایمیل آدرس."}/>
          <AsideRow title={"نوع خدمات"} active={state.registration.level >= 4} subtitle={"عنوان شغلی و نوع خدمات"}/>
          <AsideRow title={"پذیرش پیش قرارداد"} active={state.registration.level >= 5} subtitle={"پیش قرارداد همکاری با اسنپ شاپ را مطالعه و در صورت پذیرش، برای دو طرف از نظر قانونی الزام‌آور است."}/>
          <AsideRow title={"تایید فروشنده"} active={state.registration.level >= 6} subtitle={"تایید و پذیرش اطلاعات فروشنده توسط ماشین نو"}/>
        </ul>
        <div className={"line"}></div>
      </nav>
    </div>
  );
};

export default RegisterAside;